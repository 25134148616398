import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import AnimatedButton from "../components/animated-button";
import Hero from "../components/hero";
import ReviewSection from "../components/review-section";
import CTASection from "../components/cta-section";

const ServicesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Our-Mission-Statement-AU" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			esacImg: wpMediaItem(title: { eq: "ESAC-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ecsmImg: wpMediaItem(title: { eq: "SDG-circle" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			eiaImg: wpMediaItem(title: { eq: "ESG" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ssrmImg: wpMediaItem(title: { eq: "Road-Maps" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			esgImg: wpMediaItem(title: { eq: "ESG-S" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			isoImg: wpMediaItem(title: { eq: "is0-14001" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Services",
				item: {
					url: `${siteUrl}/services`,
					id: `${siteUrl}/services`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Services | ACM Consultancy Services Ltd"
				description="Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/services`,
					title: "Services | ACM Consultancy Services Ltd",
					description:
						"Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="ACM Consultancy Services"
							description="Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!"
						/>
					</section>
					<section className="py-5 pt-lg-7">
						<Container>
							<Row className="align-items-center">
								<Col lg={6}>
									<h2 className="text-dark-green fs-1 mb-5 mb-lg-0 garamond-bold">
										Championing,
										<br /> Empowerment,
										<br /> Experience,
										<br /> Facilitating,
										<br /> Resource and Legacy​
									</h2>
								</Col>
								<Col lg={6}>
									<p>
										We are an environment and sustainability consultancy firm
										who drive an equilibrium of environmental and operational
										performance, to empower a globally sustainable future
										through innovation, collaboration and expertise.
									</p>
									<p>
										We achieve maximum value for client ecosystems by delivering
										the six key steps within our{" "}
										<Link className="black-link" to="/about-us">
											mission statement
										</Link>
										.
									</p>
									<p>
										Please explore our range of key services on this page.
										Please do not hesitate to{" "}
										<Link className="black-link" to="/contact-us">
											get in touch
										</Link>{" "}
										if there is a bespoke service that you require.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey">
						<Container>
							<Row>
								<Col>
									<h2 className=" text-center  text-primary display-4">
										Our pursuit of the 17 UN Sustainable Development Goals
										(UNSDGs)
									</h2>
									<p className="text-dark-green text-center garamond-bold ">
										Heartfelt drive towards true sustainability
									</p>
								</Col>
							</Row>
							<Row>
								<Col>
									<hr className="bg-black mb-5" />
								</Col>
							</Row>
							<Row className="g-5 g-lg-6">
								<Col md={6} xl={4}>
									<BgImage
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center w-100 justify-content-center position-relative"
										image={getImage(
											data.esacImg.localFile.childImageSharp.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div
											className="position-absolute text-center translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												Environment and Sustainability Advisors and Consultants
											</p>
										</div>
									</BgImage>
								</Col>
								<Col md={6} xl={4}>
									<BgImage
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center w-100 justify-content-center position-relative"
										image={getImage(
											data.ecsmImg.localFile.childImageSharp.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div
											className="position-absolute text-center translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												ISO14001 Environment, Consenting and Sustainability
												Management
											</p>
										</div>
									</BgImage>
								</Col>
								<Col md={6} xl={4}>
									<BgImage
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center w-100 justify-content-center position-relative"
										image={getImage(
											data.eiaImg.localFile.childImageSharp.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div
											className="position-absolute text-center translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												Environmental Impact Assessment
											</p>
										</div>
									</BgImage>
								</Col>
								<Col md={6} xl={4}>
									<BgImage
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center w-100 justify-content-center position-relative"
										image={getImage(
											data.ssrmImg.localFile.childImageSharp.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div
											className="position-absolute text-center translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												Sustainability Strategy, Road Maps and Performance
												Reporting
											</p>
										</div>
									</BgImage>
								</Col>
								<Col md={6} xl={4}>
									<BgImage
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center w-100 justify-content-center position-relative"
										image={getImage(
											data.esgImg.localFile.childImageSharp.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div
											className="position-absolute text-center translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												Environment, Social, Governance (ESG) and Positive
												Communities
											</p>
										</div>
									</BgImage>
								</Col>
								<Col md={6} xl={4}>
									<div
										style={{ paddingTop: "100%" }}
										className=" d-flex align-items-center bg-dark-green w-100 justify-content-center position-relative"
									>
										<div
											className="position-absolute  translate-middle px-4 w-100 h-100 px-0 d-flex align-items-center justify-content-center  start-50 top-50"
											style={{ zIndex: 2 }}
										>
											<p className="text-white w-100 fs-4 inter-regular">
												As a passionate SME, we create rational change to
												transition and drive towards meaningful holistic
												outcomes that work now and in the future.
											</p>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-dark-green ">
						<Container>
							<Row>
								<Col>
									<hr className="bg-white mb-5" />
								</Col>
							</Row>
							<Row>
								<Col lg={7}>
									<h2 className="  text-secondary mb-5 fs-1">
										Environment and Sustainability Advisors and Consultants
									</h2>
									<p className="text-white">
										ACM Consultancy Services Ltd works with clients around the
										world to provide environmental and sustainability expertise
										and advice, education, training and consultancy across the
										full lifecycle of your projects.
									</p>
									<p className="text-white">
										Our experienced consultants provide advice on current and
										emerging environment and sustainability issues and impacts,
										as well as upon impact avoidance, efficiency, mitigation,
										stewardship, regeneration, re-wilding, offsetting and net
										gain.
									</p>
									<p className="text-white">
										When integrating our expert skills, knowledge and technical
										abilities into the heart of a project we drive fresh
										thinking to achieve optimised results and innovation.
									</p>
									<p className="text-white mb-5">
										We deliver an equilibrium of environmental and operational
										performance and help you transition to rational and
										functional change. Get in touch to learn more about how this
										service can help you!
									</p>
									<AnimatedButton
										textColour="text-white"
										buttonColour="bg-secondary"
										textWidth="180px"
										buttonId="one"
										buttonText="GET IN TOUCH"
										buttonLink="/contact-us"
									/>
								</Col>
								<Col
									className="ps-lg-6 text-center mt-5 px-4 pe-lg-0 mt-lg-0"
									lg={5}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-lightgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-lightgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-secondary text-center"
										>
											“Anna at ACM Consultancy Services Ltd.
											<br className="d-none d-md-block" /> consistently
											demonstrated superb levels of
											<br className="d-none d-md-block" /> commitment and
											professional skill in
											<br className="d-none d-md-block" /> fulfilling her role,
											being an exemplar in
											<br className="d-none d-md-block" /> terms of motivation…”
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="g-lg-7 g-5">
								<Col lg={4}>
									<GatsbyImage
										className="w-100"
										image={
											data.isoImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.isoImg.altText}
									/>
								</Col>
								<Col lg={8}>
									<h2 className="  text-primary mb-5 fs-1">
										ISO14001 Environment, Consenting and Sustainability
										Management
									</h2>
									<p>
										Are you looking to develop or manage the capabilities of
										your IS014001 Environment, Sustainability and Consenting
										Management System? Or, enhance the sustainability
										credentials of your project or organisation, in pursuit of
										delivering the UN Sustainable Development Goals?
									</p>
									<p>We can help!</p>
									<p>
										We drive collaboration in order to foster trust between all
										stakeholders (inside and outside your organisation) and are
										integral in facilitating a forum for innovation, the
										perspective on what is possible, and how this can influence
										change in our audiences.
									</p>
									<p>
										We empower a globally sustainable future by achieving
										rational change through holistic system thinking and
										fully-integrated working.
									</p>
									<p>
										As part of our agile, flexible and collaborative resource
										platform, we deliver expert Environment, Consenting and
										Sustainability Managers, whilst the technical specialist
										expertise we supply may range, for example from; ecologists
										(with BNG, NBS experience), regenerative agricultural and
										water re-use specialists - through to carbon reduction,
										circular economy and waste professionals.
									</p>
									<p>
										We also supply professionals promoting social and
										educational value, and wellbeing in communities, in our bid
										to achieve environmental net gain.
									</p>
									<p className="mb-5">
										We strive to go beyond expectations, having received a
										4-STAR award in Environmental Inspections and accolade in
										Audits.
									</p>
									<AnimatedButton
										textColour="text-white"
										buttonColour="bg-primary"
										textWidth="180px"
										buttonId="two"
										buttonText="GET IN TOUCH"
										buttonLink="/contact-us"
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-primary ">
						<Container>
							<Row>
								<Col>
									<hr className="bg-white mb-5" />
								</Col>
							</Row>
							<Row>
								<Col lg={7}>
									<h2 className="  text-white mb-5 fs-1">
										Environment and Sustainability Advisors and Consultants
									</h2>
									<p className="text-white">
										With decades of Environmental Impact Assessment Management
										experience, our staff have the skills, capabilities and
										expertise to take your business to the next level.
									</p>
									<p className="text-white">
										We will deliver your EIA (from screening and scoping,
										through to ES production, Assurance, Lead Authorship review,
										Quality Assurance and sign-off). We strive to achieve an
										equilibrium of environment and sustainability performance
										that helps provide integrated build-ability and planning
										solutions, embeds resilience and looks towards leaving a
										compliant, legacy development for clients and communities.
									</p>
									<p className="text-white">
										Through our technical expertise, high-performing quality
										assurance and dynamic and scalable resource model, we define
										project resources and translate these objectives into the
										desired outcomes in terms of sustainability, time,
										efficiency, accuracy, and quality.
									</p>
									<p className="text-white mb-5">
										We are proud to help shape and improve how our clients
										structure and manage these deliverables.
									</p>
									<AnimatedButton
										textColour="text-primary"
										buttonColour="bg-white"
										textWidth="180px"
										buttonId="three"
										buttonText="GET IN TOUCH"
										buttonLink="/contact-us"
									/>
								</Col>
								<Col
									className="ps-lg-6 text-center mt-5 px-4 pe-lg-0 mt-lg-0"
									lg={5}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-white-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-white-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-white text-center"
										>
											“I have no hesitation in recommending
											<br className="d-none d-md-block" /> ACM for contract
											roles in other projects,
											<br className="d-none d-md-block" /> and personally look
											forward to any future
											<br className="d-none d-md-block" /> opportunity to work
											with the ACM
											<br className="d-none d-md-block" /> company.”
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey ">
						<Container>
							<Row>
								<Col>
									<hr className="bg-white mb-5" />
								</Col>
							</Row>
							<Row>
								<Col lg={7}>
									<h2 className="  text-primary mb-5 fs-1">
										Sustainability Strategy, Road Maps and Performance Reporting
									</h2>
									<p>
										Our talented industry professionals create maximum value
										through the development, or enhancement, of tailored,
										fit-for-purpose Sustainability Strategies, which provide
										forward-thinking businesses, in pursuit of delivering the UN
										Sustainable Development Goals, with the transitional Road
										Map for sustainable business.
									</p>
									<p>
										We can streamline this strategy by working with you to guide
										you through implementation planning and implementation to
										ensure that our collective vision comes to life.
									</p>
									<p>
										Whether you are looking for a quarterly, or annual
										sustainability performance or progress report, we can help
										you, through our expert knowledge, research and captivating
										graphic design.
									</p>
									<p>
										We will impart knowledge that will directly or indirectly
										influence wider attitudes and beliefs and transition your
										internal and external stakeholders towards behavioural
										change.
									</p>

									<p className="mb-5">
										Effective knowledge, education, passion and communication is
										at the heart of everything we do and a core reason for our
										100% repeat customer record.
									</p>
									<AnimatedButton
										textColour="text-white"
										buttonColour="bg-primary"
										textWidth="180px"
										buttonId="four"
										buttonText="GET IN TOUCH"
										buttonLink="/contact-us"
									/>
								</Col>
								<Col
									className="pe-lg-6 text-center mt-5 px-4 ps-lg-0 mt-lg-0"
									lg={{ span: 5, order: "first" }}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-primary text-center"
										>
											“Provided consultancy services for the
											<br className="d-none d-md-block" /> Environmental Agency,
											managing the
											<br className="d-none d-md-block" /> environmental risks
											and opportunities of
											<br className="d-none d-md-block" /> highly complex,
											multimillion pound flood
											<br className="d-none d-md-block" /> defence schemes…”
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-secondary ">
						<Container>
							<Row>
								<Col>
									<hr className="bg-white mb-5" />
								</Col>
							</Row>
							<Row>
								<Col lg={7}>
									<h2 className="  text-dark-grey mb-5 fs-1">
										Environment, Social, Governance (ESG) and Positive
										Communities
									</h2>
									<p className="text-dark-grey">
										Broaden your Positive ESG Impact. Our involvement in your
										project or organisation will leave a tangible positive
										legacy for stakeholders and will transition communities to
										positive change that benefits stakeholders now and in the
										future and will transition communities and stakeholders to
										positive and sustainable change that works now, and in the
										future.
									</p>

									<p className="text-dark-grey">
										We create an equilibrium between socio-environmental and
										socio-economic outcomes through our quantitative ESG
										audits/gap-analysis, costing, benchmarking, qualitative
										direct conversations. We will share your results with you,
										and develop you a report or roadmap that includes
										cutting-edge solutions, or impart the bespoke advice and
										upskilling you require to create your own solutions. We will
										transition your organisation towards resilient and compliant
										sustainability. We sit at the forefront of emerging
										sustainability and investment developments.
									</p>

									<p className="text-dark-grey mb-5">
										Whether you are seeking to develop the whole Environment,
										Social and Governance (ESG) operations of your business, or
										to bolster one component, we can help you deliver your
										positive, green agenda.
									</p>
									<AnimatedButton
										textColour="text-white"
										buttonColour="bg-dark-grey"
										textWidth="180px"
										buttonId="five"
										buttonText="GET IN TOUCH"
										buttonLink="/contact-us"
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default ServicesPage;
