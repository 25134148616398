import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import CTASection from "../components/cta-section";
import ReviewSection from "../components/review-section";
import Hero from "../components/hero";
import CSRCardButton from "../components/csr-card-button";

const CSRPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpTeamMember {
				nodes {
					title
					slug
					teamMemberFields {
						jobTitle
						name
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			allWpCaseStudy {
				nodes {
					title
					caseStudyFields {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
					slug
				}
			}
			heroImg: wpMediaItem(title: { eq: "road-and-trees" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			allWpCsrCard(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					slug
					dateGmt
					title
					csrCardFields {
						buttonColour
						description
						link
						local
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "CSR",
				item: {
					url: `${siteUrl}/csr`,
					id: `${siteUrl}/csr`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="CSR | ACM Consultancy Services Ltd"
				description="​As a SME we are fully committed to our own CSR and take it seriously, including that of our suppliers and wider network."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/csr`,
					title: "CSR | ACM Consultancy Services Ltd",
					description:
						"​As a SME we are fully committed to our own CSR and take it seriously, including that of our suppliers and wider network.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="Company CSR"
							description="​As a SME we are fully committed to our own CSR and take it seriously, including that of our suppliers and wider network.​"
						/>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="g-6">
								{data.allWpCsrCard.nodes.map((csr, i) => (
									<Col style={{ minHeight: "100%" }} md={6} xl={4}>
										<div className="bg-light-grey position-relative h-100">
											<GatsbyImage
												style={{ height: "150px" }}
												className="w-100 "
												image={
													csr.csrCardFields.image.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={csr.csrCardFields.image.altText}
											/>
											<div className="w-100  p-4  ">
												<p>{csr.csrCardFields.description}</p>
												<div className="pb-4 position-absolute bottom-0">
													{csr.csrCardFields.local !== true && (
														<a
															target="_blank"
															rel="noreferrer"
															href={csr.csrCardFields.link}
															className="text-decoration-none "
														>
															<CSRCardButton
																textColour="text-white"
																buttonColour={csr.csrCardFields.buttonColour}
																textWidth="130px"
																buttonId={csr.slug}
																buttonText="VISIT SITE"
															/>
														</a>
													)}
													{csr.csrCardFields.local === true && (
														<a
															target="_blank"
															rel="noreferrer"
															href="https://058bc22d95030433d2e2ea61f-11492.sites.k-hosting.co.uk/wp-content/uploads/2022/10/ACM_roadmap_2022.pdf"
															className="text-decoration-none "
														>
															<CSRCardButton
																textColour="text-white"
																buttonColour={csr.csrCardFields.buttonColour}
																textWidth="130px"
																buttonId={csr.slug}
																buttonText="VISIT MAP"
															/>
														</a>
													)}
												</div>
												<div style={{ height: "60px", width: "100%" }}></div>
											</div>
										</div>
									</Col>
								))}
							</Row>
						</Container>
					</section>
					{/* <section id="roadmap" className="py-5 py-lg-7">
						<iframe
							style="border: 1px solid #777;"
							src="https://indd.adobe.com/embed/a7d4d883-1dad-4d5f-82ea-6ab1c3ebc6b5?startpage=1&allowFullscreen=true"
							width="100%"
							height="600px"
							frameborder="0"
							allowfullscreen=""
						></iframe>
					</section> */}
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default CSRPage;
