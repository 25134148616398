import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import CICForm from "../components/cic-form";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const CICPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "CIC-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			acmImg: wpMediaItem(title: { eq: "CIC-logo" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			peopleImg: wpMediaItem(title: { eq: "People" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wildlifeImg: wpMediaItem(title: { eq: "Wildlife" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			planetImg: wpMediaItem(title: { eq: "Planet" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			missionImg: wpMediaItem(title: { eq: "Mission-Statement-Image" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			missionBgImg: wpMediaItem(title: { eq: "Our-Mission-Statement" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wwwwImg1: wpMediaItem(title: { eq: "Who-Are-We-Working-With-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wwwwImg2: wpMediaItem(title: { eq: "Who-Are-We-Working-With-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wwwwImg3: wpMediaItem(title: { eq: "Who-Are-We-Working-With-3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg1: wpMediaItem(title: { eq: "CIC-Gallery-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg2: wpMediaItem(title: { eq: "CIC-Gallery-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg3: wpMediaItem(title: { eq: "CIC-Gallery-3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg4: wpMediaItem(title: { eq: "CIC-Gallery-4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg5: wpMediaItem(title: { eq: "CIC-Gallery-5" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg6: wpMediaItem(title: { eq: "CIC-Gallery-6" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg7: wpMediaItem(title: { eq: "CIC-Gallery-7" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg8: wpMediaItem(title: { eq: "CIC-Gallery-8" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg9: wpMediaItem(title: { eq: "CIC-Gallery-9" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			gImg10: wpMediaItem(title: { eq: "CIC-Gallery-10" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const responsive = {
		0: {
			items: 1,
		},
	};
	const responsive2 = {
		0: {
			items: 2,
		},
	};
	const responsive3 = {
		0: {
			items: 3,
		},
	};

	const items = [
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg1.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg1.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg2.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg2.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg3.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg3.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg4.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg4.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg5.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg5.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg6.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg6.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg7.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg7.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg8.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg8.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg9.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg9.altText}
				/>
			</div>
		</Col>,
		<Col className="py-4 px-4  ">
			<div>
				<GatsbyImage
					style={{ height: "240px" }}
					loading="eager"
					className="w-100"
					image={data.gImg10.localFile.childImageSharp.gatsbyImageData}
					alt={data.gImg10.altText}
				/>
			</div>
		</Col>,
	];

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Net Business Positive",
				item: {
					url: `${siteUrl}/netbusinesspositive`,
					id: `${siteUrl}/netbusinesspositive`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Net Business Positive | ACM Consultancy Services Ltd"
				description="We believe that our ability to thrive globally is dependent on embracing a positive sustainability agenda. We strive to create a world where all organisations operate in the interest of communities by making a positive impact on…"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/netbusinesspositive`,
					title: "Net Business Positive | ACM Consultancy Services Ltd",
					description:
						"We believe that our ability to thrive globally is dependent on embracing a positive sustainability agenda. We strive to create a world where all organisations operate in the interest of communities by making a positive impact on…",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						{" "}
						<BgImage
							Tag="section"
							className="hero-services position-relative py-5 py-lg-0"
							image={getImage(
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.2, zIndex: 0 }}
								className="w-100 h-100 position-absolute start-0 top-0 bg-black"
							></div>
							<Container
								style={{ zIndex: 2 }}
								className="h-100 position-relative"
							>
								<Row className=" pt-3 px-3 h-100 justify-content-center align-items-center">
									<Col lg={8} className="pl-0  ps-md-3">
										<h1
											className="pt-3 mb-3 text-white display-4"
											id="services-hero-title"
										>
											Net Business Positive
										</h1>
									</Col>
									<Col lg={4} xl={3}>
										<GatsbyImage
											className="w-100 "
											image={
												data.acmImg.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.acmImg.altText}
										/>
									</Col>
								</Row>
							</Container>
						</BgImage>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary fs-1 mb-5 garamond-bold">
										Vision Statement
									</h2>
									<p className="mb-5">
										We believe that our ability to thrive globally is dependent
										on embracing a positive sustainability agenda. We strive to
										create a world where all organisations operate in the
										interest of communities by making a positive impact on…
									</p>
								</Col>
							</Row>
							<Row className="g-5 mt-lg-4 justify-content-center">
								<Col className="text-center" md={6} lg={4}>
									<div
										className="mx-auto"
										style={{
											borderRadius: "100%",
											width: "150px",
											height: "150px",
											boxShadow: "0px 3px 70px #00000029",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											style={{
												width: "150px",
												height: "150px",
											}}
											image={
												data.peopleImg.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.peopleImg.altText}
										/>
									</div>
									<p className="garamond-bold fs-4 mt-4">People</p>
								</Col>
								<Col className="text-center" md={6} lg={4}>
									<div
										className="mx-auto"
										style={{
											borderRadius: "100%",
											width: "150px",
											height: "150px",
											boxShadow: "0px 3px 70px #00000029",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											style={{
												width: "150px",
												height: "150px",
											}}
											image={
												data.wildlifeImg.localFile.childImageSharp
													.gatsbyImageData
											}
											alt={data.wildlifeImg.altText}
										/>
									</div>
									<p className="garamond-bold fs-4 mt-4">Wildlife</p>
								</Col>
								<Col className="text-center" md={6} lg={4}>
									<div
										className="mx-auto"
										style={{
											borderRadius: "100%",
											width: "150px",
											height: "150px",
											boxShadow: "0px 3px 70px #00000029",
											overflow: "hidden",
										}}
									>
										<GatsbyImage
											style={{
												width: "150px",
												height: "150px",
											}}
											image={
												data.planetImg.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.planetImg.altText}
										/>
									</div>
									<p className="garamond-bold fs-4 mt-4">Planet</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row className="g-5 align-items-center">
								<Col lg={6}>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.missionImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.missionImg.altText}
									/>
								</Col>
								<Col lg={6}>
									<h2 className="text-primary fs-1 mb-4 garamond-bold">
										Who are We?
									</h2>
									<p className="">
										We are a SME company that works in the community interest
										(whether that be local or international) working with other
										businesses, local communities, trusts, community interest
										companies and not-for-profit organisations
										(multi-stakeholders) to deliver a net positive business
										effect.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<BgImage
							Tag="section"
							className=" position-relative  py-lg-0"
							image={getImage(
								data.missionBgImg.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.4, zIndex: 0 }}
								className="w-100 h-100 position-absolute start-0 top-0 bg-black"
							></div>
							<Container
								style={{ zIndex: 2 }}
								className="h-100 position-relative py-6 py-lg-10"
							>
								<Row className=" pt-3 px-3 h-100  align-items-center">
									<Col lg={8} className="pl-0  ps-md-3">
										<h2 className="text-white fs-1 mb-5 garamond-bold">
											Our Mission Statement
										</h2>
										{/* <p className="text-white">
											When integrating our skills, knowledge and technical
											abilities into the heart of a project we drive fresh
											thinking to achieve optimised results. In order to drive
											collaboration, we foster trust between all stakeholders.
											We expand the perspective on what is reasonably possible,
											and how this can influence change. Our involvement will
											leave a tangible positive legacy for stakeholders that
											strives to make a real difference for people, wildlife and
											planet.
										</p>
										<p className="text-white">
											We will work with specific national and international
											communities to deliver the three pillars of sustainability
											(environmental/social/economic). We will use pilot
											sustainability strategies and projects, as our guiding arm
											for the surrounding communities to enable them to become
											more self sufficient. We will report our mission through
											the language of the UN Sustainable Development Goals.
										</p> */}
										<ul className="text-white ps-3 ps-md-4">
											<li className="mb-3">
												When integrating our skills, knowledge and technical
												abilities into the heart of a project we drive fresh
												thinking to achieve optimised results.
											</li>
											<li className="mb-3">
												In order to drive collaboration, we foster trust between
												all stakeholders.
											</li>
											<li className="mb-3">
												We expand the perspective on what is reasonably
												possible, and how this can influence change.
											</li>
											<li className="mb-3">
												Our involvement will leave a tangible positive legacy
												for stakeholders that strives to make a real difference
												for people, wildlife and planet.
											</li>
											<li className="mb-3">
												We work with specific national and international
												communities to deliver the three pillars of
												sustainability (environmental/social/economic).
											</li>
											<li className="mb-3">
												We use pilot sustainability strategies and projects, as
												our guiding arm for the surrounding communities to
												enable them to become more self-sufficient.
											</li>
											<li className="mb-3">
												We report our mission through the language of the UN
												Sustainable Development Goals.
											</li>
										</ul>
									</Col>
								</Row>
							</Container>
						</BgImage>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary text-center fs-1 mb-5 garamond-bold">
										Who are we working with
									</h2>
								</Col>
							</Row>
							<Row className="g-5 align-items-center">
								<Col lg={6}>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.wwwwImg1.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.wwwwImg1.altText}
									/>
								</Col>
								<Col lg={6}>
									<p className="">
										We are working as a SME company in the national and local
										community interest, but we also partner with other global
										community organisations who work on the ground in other
										countries to deliver elements of sustainability in
										collaboration with international and in-country expertise.
										We ensure ethical and transparent raising, tracking and
										distribution of community funds - and we have declared to
										give a % of our profits - to non profits - having done so
										already - and will continue to do so in the future (as part
										of the net positive business plan).
									</p>
								</Col>
								<Col className="d-lg-none" lg={6}>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.wwwwImg2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.wwwwImg2.altText}
									/>
								</Col>
								<Col lg={6}>
									<p className="">
										For instance, we previously contributed to Community Health
										Empowerment Organisation in Butuntumula – Luwero, Uganda who
										are an expert community health and social care organisation
										delivering social and healthcare sustainability to Ugandan
										communities in consultation with those communities and
										community leaders. We helped develop their technical
										proposal for the area (non-profit) and collaborated with an
										international agronomist working in Uganda, as we as the
										healthcare team.
									</p>
								</Col>
								<Col className=" d-none d-lg-block" lg={6}>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.wwwwImg2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.wwwwImg2.altText}
									/>
								</Col>
								<Col lg={6}>
									{" "}
									<GatsbyImage
										className="w-100 "
										image={
											data.wwwwImg3.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.wwwwImg3.altText}
									/>
								</Col>
								<Col lg={6}>
									<p className="">
										We also helped to finance their rent, agriculture, school,
										and other community contributions for over one year.
									</p>
									<p>
										We deliver to the three key pillars of sustainability
										(Environmental, Social and Economic) and report the outcomes
										of our vision and mission statement in the language of a
										number of the UN Sustainable Development Goals, ensuring
										that value for the local area is reached - agreeing a joint
										vision, with local leaders.
									</p>
									{/* <p>
										We sit on a number of regarded steering groups and that has
										included international members".
									</p> */}
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary  fs-1 mb-5 garamond-bold">
										Contact us
									</h2>
								</Col>
							</Row>
							<Row className="g-5">
								<Col lg={6}>
									<CICForm />
								</Col>
								<Col className="mt-lg-6" lg={6}>
									<Row className=" align-items-center mb-5">
										<Col
											xs={{ offset: 2 }}
											md={{ offset: 1 }}
											lg={{ offset: 2 }}
										>
											<h3 className="gramond-bold  ">Anna Hewson</h3>
										</Col>
									</Row>
									<Row className=" align-items-center mb-5">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/email-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={10} md={11} lg={10}>
											<a
												href="mailto:anna@acmconsultancyservices.com"
												className="black-link d-block inter-regular  "
											>
												anna@acmconsultancyservices.com
											</a>
										</Col>
									</Row>
									<Row className=" align-items-center mb-5">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/phone-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={10} md={11} lg={10}>
											<a
												href="tel:+447748205717"
												className="black-link d-block inter-regular  "
											>
												+44 (0)7748205717
											</a>
										</Col>
									</Row>
									<Row className="  ">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/location-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={10} md={11} lg={10}>
											<a
												target="_blank"
												rel="noreferrer"
												href="https://goo.gl/maps/9oXAmgcA3Wftzg8a9"
												className="black-link d-block inter-regular "
											>
												Windsor House Troon
												<br /> Way Business Centre,
												<br /> Humberstone Lane,
												<br /> Thurmaston,
												<br /> Leicestershire, England,
												<br /> LE4 9HA
											</a>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary  fs-1 mb-5 garamond-bold">
										Gallery
									</h2>
								</Col>
							</Row>
							<Row className="d-md-none">
								<AliceCarousel
									infinite={true}
									responsive={responsive}
									mouseTracking
									items={items}
									disableDotsControls
									autoPlay
									autoPlayInterval={2000}
								/>
							</Row>
							<Row className="d-none d-md-flex d-lg-none">
								<AliceCarousel
									infinite={true}
									responsive={responsive2}
									mouseTracking
									items={items}
									disableDotsControls
									autoPlay
									autoPlayInterval={2000}
								/>
							</Row>
							<Row className="d-none d-lg-flex ">
								<AliceCarousel
									infinite={true}
									responsive={responsive3}
									mouseTracking
									items={items}
									disableDotsControls
									autoPlay
									autoPlayInterval={2000}
								/>
							</Row>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default CICPage;
