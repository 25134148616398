import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import AnimatedButton from "../components/animated-button";
import CTASection from "../components/cta-section";
import Hero from "../components/hero";

const MediaPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpTeamMember {
				nodes {
					title
					slug
					teamMemberFields {
						jobTitle
						name
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			allWpCaseStudy {
				nodes {
					title
					caseStudyFields {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
					slug
				}
			}
			heroImg: wpMediaItem(title: { eq: "Media-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			wildingImg: wpMediaItem(title: { eq: "wilding" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			lynxImg: wpMediaItem(title: { eq: "lynx" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			regenImg: wpMediaItem(title: { eq: "regbook" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			soilImg1: wpMediaItem(title: { eq: "soilfood-graph" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			soilImg2: wpMediaItem(title: { eq: "soilfood" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			soilImg3: wpMediaItem(title: { eq: "the-soil-food-web" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			economyImg: wpMediaItem(title: { eq: "butterfly" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ncImg: wpMediaItem(title: { eq: "natural-circle" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			cfImg: wpMediaItem(title: { eq: "footprint" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			ifrImg: wpMediaItem(title: { eq: "Important-Farmers" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			awImg: wpMediaItem(title: { eq: "Asbestos" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Media",
				item: {
					url: `${siteUrl}/media`,
					id: `${siteUrl}/media`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Media | ACM Consultancy Services Ltd"
				description="Resources we rate and want to share with you. Note: We are not affiliated with any of this media, it is simply for educational purposes because it is creative, inspiring, or technically informative (or all 3!)"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/media`,
					title: "Media | ACM Consultancy Services Ltd",
					description:
						"Resources we rate and want to share with you. Note: We are not affiliated with any of this media, it is simply for educational purposes because it is creative, inspiring, or technically informative (or all 3!)",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="Media"
							description="Resources we rate and want to share with you. Note: We are not affiliated with any of this media, it is simply for educational purposes because it is creative, inspiring, or technically informative (or all 3!)"
						/>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey ">
						<Container>
							<Row>
								<Col lg={3}>
									<GatsbyImage
										className="w-100"
										image={
											data.wildingImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.wildingImg.altText}
									/>
								</Col>
								<Col lg={4} xl={5}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										Wilding, a book by Isabella Tree
									</h2>
									<p>
										In Wilding, Isabella Tree tells the story of the 'Knepp
										experiment', a pioneering rewilding project in West Sussex,
										using free-roaming grazing animals and regenerative
										agro-forestry systems to create new habitats for wildlife. A
										fascinating and hopeful account of the ecology of our
										countryside and the inspiring journey Isabella and her
										husband took to get there. A fine example of bolstering
										natural, social and economic capital.
									</p>
								</Col>
								<Col
									className=" text-center mt-5 px-4 pe-lg-0 mt-lg-0"
									lg={5}
									xl={4}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-primary inter-regular text-center"
										>
											The book describes an attempt to renew{" "}
											<br className="d-none d-md-block" /> the ecosystem, after
											decades of intensive <br className="d-none d-md-block" />{" "}
											agriculture of some 1,400 hectares owned{" "}
											<br className="d-none d-md-block" /> by Tree’s husband
											Charlie Burrell at Knepp{" "}
											<br className="d-none d-md-block" /> in West Sussex. The
											project, which began <br className="d-none d-md-block" />{" "}
											in 2001, is perhaps unique in England, and{" "}
											<br className="d-none d-md-block" /> the results have been
											spectacular <br className="d-none d-md-block" />
											(Guardian, 2018).
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-white ">
						<Container>
							<Row>
								<Col lg={{ span: 4, order: "last" }}>
									<GatsbyImage
										className="w-100"
										image={
											data.lynxImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.lynxImg.altText}
									/>
								</Col>
								<Col lg={4}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										The Lynx And Us
									</h2>
									<p>
										Discusses key issues, such as the role of apex predators
										(Lynx) in the animal kingdom, particularly the viability of
										a self-sustaining population of Lynx in Scotland, the
										complexity of the earth’s wild systems and how apex
										predators are an important part of this, and how
										occasionally predators may come into contact with humans but
										it is unlikely. Embellished with wildlife photography and an
										inspiring read for wildlife enthusiasts, whichever side of
										the fence you sit on.
									</p>
								</Col>
								<Col
									className=" text-center mt-5 px-4 ps-lg-0 mt-lg-0"
									lg={{ span: 4, order: "first" }}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-primary inter-regular text-center"
										>
											A forest is more than trees, it is a web of
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											life. It is complex and includes many
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											thousands of different organisms all
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											performing an important role, from the
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											plants that make up the different layers
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											of the forest to the fungi that help to
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											recycle nutrients by decomposing dead
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											material. Animals feed on the plants and
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											each other to help ensure a natural
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											balance. Crucial to this natural balance
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											are the predators because they help to
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											ensure there are not too many other
											<br className="d-none d-md-block d-lg-none d-xl-block" />{" "}
											animals eating the plants.
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey ">
						<Container>
							<Row className="align-items-center">
								<Col lg={3}>
									<GatsbyImage
										className="w-100"
										image={
											data.regenImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.regenImg.altText}
									/>
								</Col>
								<Col lg={4} xl={5}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										Regeneration, a book by Andrew Painting
									</h2>
									<p>
										The successes and social, ecological and political
										complexities of nature recovery, all tied together in the
										case study of nature regeneration at Mar Lodge in Scotland.
									</p>
								</Col>
								<Col
									className=" text-center mt-5 px-4 pe-lg-0 mt-lg-0"
									lg={5}
									xl={4}
								>
									<div style className="position-relative d-inline-block pt-4 ">
										<div
											style={{ width: "20px", left: "-10px" }}
											className="position-absolute  top-0"
										>
											<StaticImage
												quality="70"
												src="../images/left-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>
										<div
											style={{ width: "20px", right: "-10px" }}
											className="position-absolute   top-0"
										>
											<StaticImage
												quality="70"
												src="../images/right-darkgreen-leaf.svg"
												alt="quote"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</div>

										<p
											style={{ fontSize: "100%" }}
											className="text-primary inter-regular text-center"
										>
											Deftly weaving through the social and political
											complexities of nature conservation in Scotland the
											Regeneration of Mar Lodge is testimony to the miracles
											that can happen when disparate interests come together in
											common cause. Beautifully written, often funny, sometimes
											very moving, always fascinating in its historical and
											ecological detail, Andrew Painting has given us a gift of
											insight, wisdom and vaulting ambition for a wilder, richer
											future for the Highlands of Scotland. A hugely enjoyable
											and inspiring read' -- Isabella Tree, author of 'Wilding:
										</p>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										Fools & Dreamers
									</h2>
									<p>
										Regenerating a Native Forest is an incredibly inspiring,
										free 30-minute documentary about Hinewai Nature Reserve, on
										New Zealand’s Banks Peninsula, and its kaitiaki/manager of
										30 years, botanist Hugh Wilson
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.youtube.com/watch?v=3VZSJKbzyMc"
										className="primary-link "
									>
										Click here to watch the video
									</a>
								</Col>
							</Row>

							<Row className="justify-content-center pt-5">
								<Col>
									<div className="iframe-container-new">
										<iframe
											loading="lazy"
											className="responsive-iframe"
											src="https://www.youtube.com/embed/3VZSJKbzyMc"
											title="Fools & Dreamers"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowfullscreen
										></iframe>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey ">
						<Container>
							<Row className="align-items-center">
								<Col lg={5}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										Soil Food Web
									</h2>
									<p>
										An exploration of Regenerative Agriculture resources from
										composting to nematodes.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.soilfoodweb.com"
										className="primary-link "
									>
										Click here to learn more about Soil Food Web
									</a>
								</Col>
								<Col lg={7}>
									<Row className="align-items-center">
										<Col lg={7}>
											<GatsbyImage
												className="w-100 mt-4 mt-lg-0"
												image={
													data.soilImg1.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={data.soilImg1.altText}
											/>
										</Col>
										<Col lg={5}>
											<GatsbyImage
												className="w-100 mt-4 mt-lg-0"
												image={
													data.soilImg2.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={data.soilImg2.altText}
											/>
										</Col>
									</Row>
									<Row className="justify-content-center">
										<Col lg={7}>
											<GatsbyImage
												className="w-100 mt-4"
												image={
													data.soilImg3.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={data.soilImg3.altText}
											/>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-white ">
						<Container>
							<Row className="align-items-center g-lh-5">
								<Col lg={6}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										The circular economy
									</h2>
									<p>
										The circular economy is based on three principles, driven by
										design that are needed in society and communities in a
										sustainable world: Eliminate waste and pollution / Circulate
										products and materials (at their highest value) / Regenerate
										nature.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://ellenmacarthurfoundation.org/topics/circular-economy-introduction/overview"
										className="primary-link "
									>
										Click here to learn more
									</a>
								</Col>
								<Col lg={{ span: 6, order: "first" }}>
									<GatsbyImage
										className="w-100 mt-4 mt-lg-0"
										image={
											data.economyImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.economyImg.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7 bg-light-grey ">
						<Container>
							<Row className="g-lg-5">
								<Col lg={8}>
									<h2 className=" garamond-bold mt-4 mt-lg-0  mb-4 fs-3">
										Enabling A Natural Capital Approach
									</h2>
									<p>
										Enabling a Natural Capital Approach (ENCA) is an approach to
										valuing our natural capital i.e. our asset stocks (e.g.
										trees, flowers, water, biodiversity etc.) and the ecosystem
										services (e.g. timber, pollination etc.) and interactions
										that sit between them (as best as is possible) in a stronger
										manner. All of our other capitals (manufactured, economic,
										social etc.) sit on top of natural capital stocks and rely
										on natural capital. So the value of Natural Capital must be
										correctly placed (as well for nature’s sake itself - in
										terms of addressing the nature emergency). The Natural
										Capital approach accounts for nature in decision-making and
										can provide ecosystem services for people AND wildlife, as
										well as restore lost biodiversity in a way that is valued
										better.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.gov.uk/guidance/enabling-a-natural-capital-approach-enca"
										className="primary-link  d-block"
									>
										Learn more about ENCA
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://aecom.com/uk/natural-capital-laboratory/"
										className="primary-link  mt-4"
									>
										Learn more about Natural Capital Laboratory
									</a>
								</Col>
								<Col lg={{ span: 4 }}>
									<GatsbyImage
										className="w-100 mt-4 mt-lg-0"
										image={data.ncImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.ncImg.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-dark-green garamond-bold fs-1 text-center">
										Company Inspirations
									</h2>
									<p className="text-center mb-5">
										A number of family-related and non-family related
										inspirational media pieces and links that are relevant to
										the Director who set the ethos for the company.
									</p>
								</Col>
							</Row>
							<Row className="g-5">
								<Col className="text-center" lg={4}>
									<h3 className="fs-5 text-primary mb-4">
										Robin Khan - Forestry Commission Head Ranger, Tiger
										Photographer, Wildlife Talks, and Bird Expert:
									</h3>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.independent.co.uk/travel/macho-young-men-stand-on-the-roofs-of-apartment-blocks-in-italy-and-shoot-the-honey-buzzards-1594826.html"
										className="black-link d-block  mt-4"
									>
										Independent
									</a>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.devonlive.com/news/devon-news/tributes-paid-exeter-renowned-modest-4322471"
										className="black-link  mt-4"
									>
										Devon Live
									</a>
								</Col>
								<Col className="text-center" lg={4}>
									<h3 className="fs-5 text-primary mb-4">
										Inspirational Others (Not Personally Known):
									</h3>
									<p className="my-0 py-0" style={{ fontSize: "100%" }}>
										Diane Fossey -
										<a
											target="_blank"
											rel="noreferrer"
											href="https://gorillafund.org/who-we-are/dian-fossey/dian-fossey-bio/"
											className="black-link  "
										>
											gorillafund
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										Jane Goodall -
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.janegoodall.org/"
											className="black-link mt-4"
										>
											Janegoodall
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										David Attenborough -
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.bbc.co.uk/teach/nine-astonishing-ways-david-attenborough-shaped-your-world/z4k2kmn"
											className="black-link mt-4 "
										>
											BBC
										</a>
									</p>
								</Col>
								<Col className="text-center" lg={4}>
									<h3 className="fs-5 text-primary mb-4">
										Heather Woodland - Hobby-rearing, Barn Owl surveying,
										Dormouse rearing, Bird-ringing and Bird Expert:
									</h3>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.bto.org/our-science/projects/breeding-bird-survey/taking-part/volunteer-stories"
										className="black-link d-block  mt-4"
									>
										Breeding Bird Survey
									</a>
								</Col>
							</Row>
						</Container>
					</section>
					<section className=" pt-lg-7">
						<Container>
							<Row className="align-items-center g-5">
								<Col lg={5}>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.compareyourfootprint.com/partners/ambassadors/acm/"
										className=" d-block position-relative mt-4"
									>
										<GatsbyImage
											className="w-90 "
											image={
												data.cfImg.localFile.childImageSharp.gatsbyImageData
											}
											alt={data.cfImg.altText}
										/>
										<div className="hover-vanish bg-primary position-absolute start-0 top-0 w-100 h-100 d-flex align-items-end justify-content-end">
											<p
												style={{ fontSize: "100%" }}
												className="text-white pe-3 pb-0 mb-1"
											>
												Click footprint to access
											</p>
										</div>
									</a>
								</Col>
								<Col lg={7}>
									<p className="pb-0 mb-0">
										We partner with Compare Your Footprint carbon accounting
										services, as well as collaborate with ‘Auditel’ and ‘Net
										Zero’ carbon footprint consultants.”
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="align-items-center g-5">
								<Col lg={6}>
									<GatsbyImage
										className="w-100 "
										image={
											data.ifrImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.ifrImg.altText}
									/>
								</Col>
								<Col lg={6}>
									<h2 className="text-primary fs-2 ">
										Important farmer resources (asbestos)
									</h2>
									<p className="mb-4">
										Agricultural workers and farmers work on farms to produce
										crops, livestock and dairy products. Farmers are at risk of
										exposure to asbestos through soil, vermiculite and products
										related to farming equipment and machinery. Asbestos.com
										organisation provides free informational books, packets and
										a Patient Advocacy program that works 1-on-1 with
										individuals to help them find local doctors, treatment
										centers and support groups.
									</p>
									<a
										target="_blank"
										rel="noreferrer"
										href="https://www.asbestos.com/"
										className="text-decoration-none "
									>
										<AnimatedButton
											textColour="text-white"
											buttonColour="bg-primary"
											textWidth="170px"
											buttonId="one"
											buttonText="VISIT WEBSITE"
										/>
									</a>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-3">
						<Container>
							<Row className=" h-100 g-5">
								<Col
									style={{ minHeight: "100%" }}
									lg={{ span: 6, order: "last" }}
								>
									<GatsbyImage
										className="w-100 d-lg-none"
										image={data.awImg.localFile.childImageSharp.gatsbyImageData}
										alt={data.awImg.altText}
									/>
									<BgImage
										Tag="section"
										style={{ minHeight: "100%", backgroundPosition: "bottom" }}
										className="w-100 d-none d-lg-block"
										image={getImage(
											data.awImg.localFile.childImageSharp.gatsbyImageData
										)}
									></BgImage>
								</Col>
								<Col lg={6}>
									<h2 className="text-primary fs-2 ">
										How Agricultural Workers Are Exposed to Asbestos
									</h2>
									<p>
										While the work that farmers do today is different from the
										work performed during the height of asbestos use in the
										mid-20th century, asbestos exposure remains a risk to farm
										workers.
									</p>
									<p>
										Much of the equipment and machinery used on farms is known
										to contain asbestos products. Older farm buildings —
										including farmhouses, barns, silos, pens and sheds — are
										highly likely to contain asbestos construction materials.
										Asbestos cement pipes were commonly used as field drains on
										farmlands. Repair and maintenance of equipment, machinery
										and farm structures could expose farmers to asbestos.
									</p>
									<p>
										Farmers may also be exposed to asbestos in contaminated
										vermiculite products or contaminated soil in areas of
										naturally occurring asbestos. Animal husbandry, digging up
										asbestos-laden soil, crop planting and maintenance with
										asbestos- contaminated vermiculite are other sources of
										exposure. Newer sources of asbestos exposure on farms may
										come from machinery parts, including aftermarket brakes,
										brake linings, other vehicle friction materials and gaskets.
										Farming remains among the jobs that encounter asbestos
										frequently.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7 pt-3">
						<Container>
							<Row className=" g-5">
								<Col lg={6}>
									<h2 className="text-primary fs-2 ">
										Epithelial mesothelioma
									</h2>
									<p>
										Epithelial mesothelioma Mesothelioma is a rare and deadly
										cancer that forms in the lining of the lungs or abdomen and
										is caused by exposure to asbestos fibers. The survival rate
										for malignant mesothelioma is often low, and the average
										life expectancy is around 12-21 months. A mesothelioma
										specialist may be able to improve a patient’s prognosis with
										a customised treatment plan using surgery, chemotherapy, and
										radiation. It is encountered a lot in the world of property
										and infrastructure, and should be properly regulated.{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.mesotheliomahope.com/mesothelioma/epithelioid/"
											className="black-link   "
										>
											Epithelial mesothelioma
										</a>{" "}
										, also known as epithelioid malignant mesothelioma, is the
										most common of the three types of mesothelioma cancer.
										Specifically, it is cancer that develops in epithelial cells
										that line the portion of the airway known as the bronchus
										and is the most treatable with prognosis of 1-2 years after
										delivery with treatment like radiotherapy or chemotherapy.
									</p>
								</Col>

								<Col lg={6}>
									<h2 className="text-primary fs-2 ">
										Other Video/Media Links:
									</h2>

									<p className="my-0 py-0" style={{ fontSize: "100%" }}>
										1. Countryfile -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.bbc.co.uk/programmes/m000yq08"
											className="black-link  "
										>
											BBC
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										2. Cairngorms Connect -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.youtube.com/watch?v=w1WW1irpt2U"
											className="black-link mt-4"
										>
											YouTube
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										3. EU Taxonomy -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://finance.ec.europa.eu/sustainable-finance/tools-and-standards/eu-taxonomy-sustainable-activities_en"
											className="black-link mt-4 "
										>
											Europa
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										4. Soil Food Web -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.soilfoodweb.com/how-it-works/"
											className="black-link mt-4 "
										>
											Soilfoodweb
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										5. USDA Soil Food Web -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.nrcs.usda.gov/wps/portal/nrcs/detailfull/soils/health/biology/?cid=nrcs142p2_053868"
											className="black-link mt-4 "
										>
											USDA
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										6. Guy Martin’s - the world’s fastest electric car -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.channel4.com/programmes/guy-martin-the-worlds-fastest-electric-car"
											className="black-link mt-4 "
										>
											Channel4
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										7. Live Links from Farmers to Classrooms -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://leaf.eco/farmertime/home"
											className="black-link mt-4 "
										>
											Leaf.eco
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										8. Big Barn - farms directly to table -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.bigbarn.co.uk/"
											className="black-link mt-4 "
										>
											Bigbarn
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										9. The Circular Economy Diagram -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://ellenmacarthurfoundation.org/circular-economy-diagram"
											className="black-link mt-4 "
										>
											ellenmacarthurfoundation
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										10. Mesothelioma Lawyer Center provides families with
										comprehensive mesothelioma information, as well as
										supporting those affected through recovering funds for
										medical treatment. -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://www.mesotheliomalawyercenter.org/"
											className="black-link mt-4 "
										>
											MesotheliomaLawyerCenter.org
										</a>
									</p>
									<p className=" my-0 py-0" style={{ fontSize: "100%" }}>
										11. A leaflet explaining the 'ins and outs' of asbestos. -{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="http://lanierlawfirm.com/mesothelioma/asbestos-exposure/"
											className="black-link mt-4 "
										>
											Asbestos Exposure
										</a>
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default MediaPage;
