import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import TeamCard from "../components/team-card";
import Hero from "../components/hero";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ReviewSection from "../components/review-section";
import CTASection from "../components/cta-section";

const AboutUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpTeamMember {
				nodes {
					title
					slug
					teamMemberFields {
						jobTitle
						name
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}

			heroImg: wpMediaItem(title: { eq: "About-Us-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			treesImg: wpMediaItem(title: { eq: "trees" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ourVisionImg: wpMediaItem(title: { eq: "Our-Vision-AU" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ourMissionImg: wpMediaItem(title: { eq: "Our-Mission-Statement-AU" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			internImg: wpMediaItem(title: { eq: "interns" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const responsive = {
		0: {
			items: 1,
		},
	};
	const responsive2 = {
		0: {
			items: 2,
		},
	};
	const responsive3 = {
		0: {
			items: 3,
		},
	};
	const responsive4 = {
		0: {
			items: 4,
		},
	};

	const items = data.allWpTeamMember.nodes.map((member) => (
		<TeamCard
			img={
				member.teamMemberFields.image.localFile.childImageSharp.gatsbyImageData
			}
			imgAlt={member.teamMemberFields.image.altText}
			name={member.teamMemberFields.name}
			jobTitle={member.teamMemberFields.jobTitle}
			link={`/team-members/${member.slug}`}
			id={member.slug}
		/>
	));

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "About Us",
				item: {
					url: `${siteUrl}/about-us`,
					id: `${siteUrl}/about-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="About Us | ACM Consultancy Services Ltd"
				description="Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!"
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/about-us`,
					title: "About Us | ACM Consultancy Services Ltd",
					description:
						"Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							bgPos="top"
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="About us"
							description="Do you think enhancing environmental and sustainability performance is the best way to deliver your commercial project? We think it is and we can prove it!"
						/>
					</section>
					<section className="py-5 py-lg-7">
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center">
								<Col className="px-0 mb-5 mb-lg-0" xl={4} lg={5}>
									{" "}
									<GatsbyImage
										className="w-100"
										image={
											data.treesImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.treesImg.altText}
									/>
								</Col>
								<Col className="px-lg-5 px-4" xl={8} lg={7}>
									<p className="mb-6">
										Since 2017, clients have turned to us, a professional
										Environment and Sustainability SME Consulting Firm that is
										ready to deliver tailored, transformative, quality solutions
										to tricky questions, on time and within budget. Our clients
										buy into our shared sustainability ethos, vision and
										mission. We are proud to have a 100% repeat customer record,
										across clients. We have won two international awards,
										including:
									</p>
									<Row>
										<Col lg={6}>
											<div className="hover-fade  text-center mb-5 mb-lg-0">
												<StaticImage
													quality="70"
													src="../images/badge-1.png"
													alt="green apple"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "80px" }}
												/>
												<p className="garamond-bold text-center mt-4">
													​Green Apple Global Environmental Best Practise, 2021
													- Acquisition International Global
												</p>
											</div>
										</Col>
										<Col lg={6}>
											<div className="hover-fade text-center">
												<StaticImage
													quality="70"
													src="../images/BADGE-2.svg"
													alt="green apple"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "55px", maxHeight: "77px" }}
												/>
												<p className="garamond-bold text-center mt-4">
													Excellence Awards: Best Environment & Sustainability
													Consulting Firm 2021 – UK
												</p>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container fluid className="px-lg-0 mx-lg-0 h-100">
							<Row className="px-lg-0 mx-lg-0 h-100">
								<Col
									className="px-0 mx-0 "
									lg={{ span: 5, order: "last" }}
									style={{ minHeight: "100%" }}
								>
									<BgImage
										Tag="section"
										style={{ minHeight: "100%" }}
										className="hero-services d-flex align-items-center justify-content-center position-relative py-5 py-lg-0"
										image={getImage(
											data.ourVisionImg.localFile.childImageSharp
												.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<h2
											style={{ zIndex: 2 }}
											className="position-relative text-white fs-1"
										>
											Our Vision
										</h2>
									</BgImage>
								</Col>
								<Col className="bg-dark-green  py-5 p-lg-5" lg={7}>
									<Row className=" justify-content-center mb-5">
										<Col
											md={1}
											className="mb-4 text-center text-md-start mb-md-0"
										>
											<StaticImage
												style={{ maxWidth: "30px" }}
												quality="70"
												src="../images/circle leaf.svg"
												alt="green apple"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={12} md={11}>
											<p className="text-white text-center text-md-start">
												We believe that our ability to thrive is dependent on
												embracing a positive green agenda.
											</p>
										</Col>
									</Row>
									<Row className=" mb-5">
										<Col
											md={1}
											className="mb-4 text-center text-md-start mb-md-0"
										>
											<StaticImage
												style={{ maxWidth: "30px" }}
												quality="70"
												src="../images/circle leaf.svg"
												alt="green apple"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={12} md={11}>
											<p className="text-white text-center text-md-start">
												We help forward-thinking businesses, stakeholders and
												individuals achieve sustainability outcomes by driving a
												lasting positive legacy.
											</p>
										</Col>
									</Row>
									<Row className=" mb-5">
										<Col
											md={1}
											className="mb-4 text-center text-md-start mb-md-0"
										>
											<StaticImage
												style={{ maxWidth: "30px" }}
												quality="70"
												src="../images/circle leaf.svg"
												alt="green apple"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={12} md={11}>
											<p className="text-white text-center text-md-start">
												We drive optimised environment and sustainability
												performance into your existing business operations, to
												empower a globally sustainable future through
												transformation, innovation, collaboration and expertise.
											</p>
										</Col>
									</Row>
									<Row className=" ">
										<Col
											md={1}
											className="mb-4 text-center text-md-start mb-md-0"
										>
											<StaticImage
												style={{ maxWidth: "30px" }}
												quality="70"
												src="../images/circle leaf.svg"
												alt="green apple"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={12} md={11}>
											<p className="text-white text-center text-md-start">
												We achieve maximum value for our client ecosystems by
												creating an equilibrium of environmental and operational
												performance through the key steps within our mission
												statement.
											</p>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container fluid className="px-lg-0 mx-lg-0 h-100">
							<Row className="px-lg-0 mx-lg-0 h-100">
								<Col className="px-0 mx-0" lg={5} style={{ minHeight: "100%" }}>
									<BgImage
										Tag="section"
										style={{ minHeight: "100%" }}
										className="hero-services d-flex align-items-center justify-content-center position-relative py-5 py-lg-0"
										image={getImage(
											data.ourMissionImg.localFile.childImageSharp
												.gatsbyImageData
										)}
									>
										<div
											style={{ zIndex: 2, opacity: 0.5 }}
											className="bg-black h-100 w-100 position-absolute start-0 top-0"
										></div>
										<div className="d-block px-md-5 px-4">
											<h2
												style={{ zIndex: 2 }}
												className="position-relative text-white fs-1"
											>
												Our Mission Statement
											</h2>
											<p
												style={{ zIndex: 2 }}
												className="position-relative text-white"
											>
												Optimal performance is achieved through informed insight
												driving effective critical thinking. We deliver our
												mission by undertaking the following key steps:
											</p>
										</div>
									</BgImage>
								</Col>
								<Col className="bg-light-grey py-5  p-lg-5" lg={7}>
									<h3 className="garamond-bold text-primary fs-4">
										Championing
									</h3>
									<p>
										We define and champion the principles, vision, goals,
										outcomes and actions for the project as an integral and
										critical function of your project’s success.
									</p>
									<h3 className="garamond-bold mt-4 text-primary fs-4">
										Empowerment
									</h3>
									<p>
										Your teams are empowered with the autonomy to operate with
										greater agility, maximising outcomes whilst minimising risk.
									</p>
									<h3 className="garamond-bold mt-4 text-primary fs-4">
										Experience
									</h3>
									<p>
										When integrating our skills, knowledge and technical
										abilities into the heart of a project we drive fresh
										thinking to achieve optimised results.
									</p>
									<h3 className="garamond-bold mt-4 text-primary fs-4">
										Facilitating
									</h3>
									<p>
										We facilitate collaboration to foster trust between all
										stakeholders, and we facilitate creative thinking, providing
										a platform for innovation, the perspective on what is
										possible, and how this can change.
									</p>
									<h3 className="garamond-bold mt-4 text-primary fs-4">
										Resource
									</h3>
									<p>
										Through our expertise and dynamic, scalable resource model,
										we define project resources, and translate objectives into
										the desired outcomes in terms of time, accuracy and quality.
										We strive to reach holistic solutions that deliver beyond
										clients expectations.
									</p>
									<h3 className="garamond-bold mt-4 text-primary fs-4">
										Legacy
									</h3>
									<p>
										Our involvement will leave a tangible positive legacy for
										stakeholders.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row>
								<Col>
									{" "}
									<h2 className="text-center mb-5 text-primary display-4">
										Meet The ACM Team
									</h2>
								</Col>
							</Row>
							<Row className="px-lg-0 mx-lg-0 d-md-none">
								<AliceCarousel
									infinite={true}
									responsive={responsive}
									mouseTracking
									items={items}
									autoPlay
									autoPlayInterval={2000}
									controlsStrategy="alternate"
								/>
							</Row>
							<Row className="px-lg-0 mx-lg-0 d-none d-md-flex d-lg-none">
								<AliceCarousel
									infinite={true}
									responsive={responsive2}
									mouseTracking
									items={items}
									autoPlay
									autoPlayInterval={2000}
									controlsStrategy="alternate"
								/>
							</Row>
							<Row className="px-lg-0 mx-lg-0 d-none d-lg-flex d-xl-none">
								<AliceCarousel
									infinite={true}
									responsive={responsive3}
									mouseTracking
									items={items}
									autoPlay
									autoPlayInterval={2000}
									controlsStrategy="alternate"
								/>
							</Row>
							<Row className="px-lg-0 mx-lg-0 d-none d-xl-flex ">
								<AliceCarousel
									infinite={true}
									responsive={responsive4}
									mouseTracking
									items={items}
									autoPlay
									autoPlayInterval={2000}
									controlsStrategy="alternate"
								/>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pt-xl-5 pb-lg-7">
						<Container>
							<Row className="align-items-center justify-content-between">
								<Col className="pb-4 pb-lg-0" lg={6}>
									<h2 className=" mb-4 text-primary display-4">Internships</h2>
									<p>
										Two of our recent international internships who we throughly
										enjoyed having at ACM. Skilled individuals from the next
										generation, enjoying the EMEX Exhibition:
									</p>
									<a
										className="primary-link"
										target="_blank"
										rel="noreferrer"
										href="https://www.emexlondon.com/"
									>
										www.emexlondon.com
									</a>
								</Col>
								<Col lg={4}>
									{" "}
									<GatsbyImage
										className="w-100"
										image={
											data.internImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.internImg.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default AboutUsPage;
