import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Col } from "react-bootstrap";
import gsap from "gsap";

const TeamCard = ({ img, imgAlt, name, jobTitle, link, id }) => {
	const enterAnimation = (btn) => {
		gsap.to(
			btn,

			{ opacity: 1, duration: 0.5 }
		);
	};
	const exitAnimation = (btn) => {
		gsap.to(btn, { opacity: 0, duration: 0.5 });
	};

	return (
		<Col
			className="px-1"
			onMouseEnter={() => enterAnimation(`.${id}`)}
			onMouseLeave={() => exitAnimation(`.${id}`)}
		>
			<Link to={link} className="text-decoration-none ">
				<div className="position-relative ">
					<div
						style={{
							zIndex: 2,
							opacity: 0,
							backgroundColor: "rgba(0, 0, 0, 0.65)",
							height: "10rem",
						}}
						className={`position-absolute w-100 text-center text-white bottom-0 start-0  ${id}`}
					>
						<h2 className="garamond-bold pt-3">{name}</h2>
						<p>{jobTitle}</p>
					</div>
					<GatsbyImage
						style={{ height: "30rem" }}
						className="w-100"
						image={img}
						alt={imgAlt}
					/>
				</div>
			</Link>
		</Col>
	);
};

export default TeamCard;
