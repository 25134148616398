import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import CaseStudyCard from "../components/case-study-card";
import ReviewSection from "../components/review-section";
import CTASection from "../components/cta-section";
import Hero from "../components/hero";

const CaseStudiesPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpCaseStudy(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					title
					caseStudyFields {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
					slug
				}
			}
			heroImg: wpMediaItem(title: { eq: "Case-Studies-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			perpImg: wpMediaItem(title: { eq: "perpendo-windmill" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Case Studies",
				item: {
					url: `${siteUrl}/case-studies`,
					id: `${siteUrl}/case-studies`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Case Studies | ACM Consultancy Services Ltd"
				description="We believe in taking a two-pronged approach to delivering sustainability. We believe using informed insight from experience; alongside collaborative ways of working (which are creative and inclusive) is the best way to deliver critical holistic thinking, quality technical appraisal, and provide a space for ideas-exchange and innovation. This approach is essential for successful transition from strategy, to plan, to action, to reporting."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/case-studies`,
					title: "Case Studies | ACM Consultancy Services Ltd",
					description:
						"We believe in taking a two-pronged approach to delivering sustainability. We believe using informed insight from experience; alongside collaborative ways of working (which are creative and inclusive) is the best way to deliver critical holistic thinking, quality technical appraisal, and provide a space for ideas-exchange and innovation. This approach is essential for successful transition from strategy, to plan, to action, to reporting.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							bgPos="bottom"
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="Case studies"
							description="We believe in taking a two-pronged approach to delivering sustainability. We believe using informed insight from experience; alongside collaborative ways of working (which are creative and inclusive) is the best way to deliver critical holistic thinking, quality technical appraisal, and provide a space for ideas-exchange and innovation. This approach is essential for successful transition from strategy, to plan, to action, to reporting."
						/>
					</section>
					<section className="py-5 py-lg-7">
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="g-1">
								{data.allWpCaseStudy.nodes.map((study) => (
									<CaseStudyCard
										img={
											study.caseStudyFields.image.localFile.childImageSharp
												.gatsbyImageData
										}
										imgAlt={study.caseStudyFields.image.altText}
										description={study.caseStudyFields.description}
										title={study.title}
										link={`/case-studies/${study.slug}`}
										id={study.slug}
									/>
								))}
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="text-primary fs-1 mb-5 text-center">
										ACM is Perpendo’s first partner
									</h2>
								</Col>
							</Row>
							<Row className="g-5 align-items-center">
								<Col lg={6}>
									<GatsbyImage
										className="w-100 "
										image={
											data.perpImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.perpImg.altText}
									/>
								</Col>
								<Col lg={6}>
									<p>
										Perpendo was established in 2018 with the aim of meeting the
										trend with more and more, non-financial demands, which are
										placed on companies and organizations. GDPR, ESG,
										environment etc. are today well-known terms, and Perpendo
										detected a need for an easy way to check compliance through
										an online self-assessment tool.
									</p>
									<p>
										ACM is Perpendo’s first partner, as a sustainability
										consultancy SME organisation who helped to write
										sustainability course content and establish how far
										companies are future-facing in regards to organisational
										compliance to sustainability. Not only that, but how far
										they are leaning towards delivering to corporate
										responsibilities within communities, industry expectations
										and how far they are looking to be future-facing in
										communities.
									</p>
									<p>
										Please consider contacting us in respect of purchasing or
										subscribing to a course (such as the Sustainability Manager
										or Sustainability Employee course)
									</p>
									<p>
										More on Perpendo:{" "}
										<a
											target="_blank"
											rel="noreferrer"
											href="https://perpendo.net/en/"
											className="black-link d-block  mt-4"
										>
											perpendo.net
										</a>
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default CaseStudiesPage;
