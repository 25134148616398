import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { Col } from "react-bootstrap";
import gsap from "gsap";
import AnimatedButton from "./animated-button";

const CaseStudyCard = ({ img, imgAlt, description, title, link, id }) => {
	const enterAnimation = (btn, desc) => {
		gsap.to(
			desc,

			{ opacity: 1, duration: 0.5 }
		);
		gsap.to(
			btn,

			{ maxHeight: "40rem", duration: 0.5 }
		);
	};
	const exitAnimation = (btn, desc) => {
		gsap.to(desc, { opacity: 0, duration: 0.5 });
		gsap.to(btn, { maxHeight: "15rem", duration: 0.5 });
	};

	return (
		<Col
			onMouseEnter={() => enterAnimation(`.${id}`, `.${id}-desc`)}
			onMouseLeave={() => exitAnimation(`.${id}`, `.${id}-desc`)}
			lg={6}
			xl={4}
		>
			<Link to={link} className="text-decoration-none">
				<div className="position-relative">
					<div
						style={{
							zIndex: 2,
							maxHeight: "15rem",
							backgroundColor: "rgba(0, 0, 0, 0.65)",
							overflow: "hidden",
						}}
						className={`position-absolute w-100  text-white bottom-0 start-0  ${id}`}
					>
						<div style={{ height: "40rem" }} className="p-4">
							<h2 className="gramond-bold mb-4 fs-3">{title}</h2>
							<p
								style={{ fontSize: "100%", opacity: 0 }}
								className={`${id}-desc`}
							>
								{description}
							</p>
							<div className="position-absolute bottom-0 start-0 p-4">
								<AnimatedButton
									textColour="text-white"
									buttonColour="bg-accent"
									textWidth="170px"
									buttonId={`${id}-button`}
									buttonText="READ ARTICLE"
								/>
							</div>
						</div>
					</div>
					<GatsbyImage
						style={{ height: "40rem" }}
						className="w-100"
						image={img}
						alt={imgAlt}
					/>
				</div>
			</Link>
		</Col>
	);
};

export default CaseStudyCard;
