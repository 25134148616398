import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

const BioTemplate = ({ data: { post, site } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Team Members",
				item: {
					url: `${siteUrl}/team-members`,
					id: `${siteUrl}/team-members`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/team-members/${post.slug}`,
					id: `${siteUrl}/team-members/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | ACM Consultancy Services Ltd`}
				description={post.teamMemberFields.jobTitle}
				openGraph={{
					url: `${siteUrl}/team-members/${post.slug}`,
					title: `${post.title} | ACM Consultancy Services Ltd`,
					description: post.teamMemberFields.jobTitle,
					type: "article",
					images: [
						{
							url: `${post.teamMemberFields.image.localFile.publicURL}`,
							width: `${post.teamMemberFields.image.localFile.childImageSharp.original.width}`,
							height: `${post.teamMemberFields.image.localFile.childImageSharp.original.height}`,
							alt: `${post.teamMemberFields.image.altText}`,
						},
					],
				}}
			/>
			<section>
				<Container className="py-5 py-lg-7">
					<Row className="align-items-center g-5 ">
						<Col className="" lg={6}>
							<h1 className="display-4 pb-3 text-primary ">
								{post.teamMemberFields.name}
							</h1>
							<div className="pb-lg-5 py-4 text-center text-lg-start position-relative">
								<div
									style={{ zIndex: -1, width: "150%" }}
									className="h-100 bg-dark-green d-none d-lg-block position-absolute top-0 end-0"
								></div>
								<div
									style={{ zIndex: -1 }}
									className="h-100 bg-dark-green w-100  d-lg-none position-absolute top-0 end-0"
								></div>
								<h2 className="pt-lg-3 text-white">
									{post.teamMemberFields.jobTitle}
								</h2>
							</div>
						</Col>
						<Col lg={6}>
							{" "}
							<GatsbyImage
								class="w-100  "
								image={
									post.teamMemberFields.image?.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={post.teamMemberFields.image?.localFile.altText}
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section>
				<Container className=" py-md-7 py-5">
					<Row className="justify-content-center">
						<Col>
							<div className="article-content px-md-4">
								{parse(post.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default BioTemplate;

export const pageQuery = graphql`
	query ArticleById($id: String!) {
		post: wpTeamMember(id: { eq: $id }) {
			id

			slug
			content
			title

			teamMemberFields {
				jobTitle
				name
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: FULL_WIDTH
								placeholder: BLURRED
							)
						}
					}
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
