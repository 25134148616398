import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ContactForm from "../components/contactForm";
import ReviewSection from "../components/review-section";

const ContactUsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Home-Hero-3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Contact Us",
				item: {
					url: `${siteUrl}/contact-us`,
					id: `${siteUrl}/contact-us`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Contact Us | ACM Consultancy Services Ltd"
				description="Please send us a message using the form below, e-mail us or call us! We aim to get back to you within 24 hours."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: "Contact Us | ACM Consultancy Services Ltd",
					description:
						"Please send us a message using the form below, e-mail us or call us! We aim to get back to you within 24 hours.",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						{" "}
						<BgImage
							Tag="section"
							className="hero-services position-relative py-5 py-lg-0"
							image={getImage(
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							)}
						>
							<div
								style={{ opacity: 0.5, zIndex: 0 }}
								className="w-100 h-100 position-absolute start-0 top-0 bg-black"
							></div>
							<Container
								style={{ zIndex: 2 }}
								className="h-100 position-relative"
							>
								<Row className=" pt-3 px-3 h-100 justify-content-center align-items-center">
									<Col lg={9} xl={7} className="pl-0 text-center ps-md-3">
										<h1
											className="pt-3 mb-3 text-white display-4"
											id="services-hero-title"
										>
											Contact us
										</h1>
										<p className="text-white">
											Please send us a message using the form below, e-mail us
											or call us! We aim to get back to you within 24 hours.
										</p>
									</Col>
								</Row>
							</Container>
						</BgImage>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row className="g-5">
								<Col lg={6}>
									<ContactForm />
								</Col>
								<Col className="mt-lg-6" lg={6}>
									<Row className=" align-items-center mb-5">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/email-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={10} md={11} lg={10}>
											<a
												href="mailto:anna@acmconsultancyservices.com"
												className="black-link d-block inter-regular  "
											>
												anna@acmconsultancyservices.com
											</a>
										</Col>
									</Row>
									<Row className=" align-items-center mb-5">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/phone-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>

										<Col xs={10} md={11} lg={10}>
											<a
												href="tel:+447748205717"
												className="black-link d-block inter-regular  "
											>
												+44 (0)7748205717
											</a>
										</Col>
									</Row>
									<Row className="  ">
										<Col xs={2} md={1} lg={2} className="text-end">
											<StaticImage
												style={{ maxWidth: "40px" }}
												quality="70"
												src="../images/location-green.svg"
												alt="email"
												placeholder="traced_svg"
												formats={["auto", "webp"]}
											/>
										</Col>
										<Col xs={10} md={11} lg={10}>
											<a
												target="_blank"
												rel="noreferrer"
												href="https://goo.gl/maps/9oXAmgcA3Wftzg8a9"
												className="black-link d-block inter-regular "
											>
												Windsor House Troon
												<br /> Way Business Centre,
												<br /> Humberstone Lane,
												<br /> Thurmaston,
												<br /> Leicestershire, England,
												<br /> LE4 9HA
											</a>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2419.612246680839!2d-1.08938!3d52.666981!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487761919e540001%3A0x2cbbdc8fadbc3b93!2sACM%20Consultancy%20Services%20Ltd!5e0!3m2!1sen!2suk!4v1664468227391!5m2!1sen!2suk"
							width="100%"
							height="450"
							style="border:0;"
							allowfullscreen=""
							loading="lazy"
							referrerpolicy="no-referrer-when-downgrade"
						></iframe>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default ContactUsPage;
