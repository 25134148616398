import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import Layout from "../components/layout";
import { getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { BgImage } from "gbimage-bridge";

const CaseTemplate = ({ data: { post, site } }) => {
	const siteUrl = site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Case Studies",
				item: {
					url: `${siteUrl}/case-studies`,
					id: `${siteUrl}/case-studies`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: `${post.title}`,
				item: {
					url: `${siteUrl}/case-studies/${post.slug}`,
					id: `${siteUrl}/case-studies/${post.slug}`,
				},
			},
		],
	};
	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={`${post.title} | ACM Consultancy Services Ltd`}
				description={post.caseStudyFields.description}
				openGraph={{
					url: `${siteUrl}/case-studies/${post.slug}`,
					title: `${post.title} | ACM Consultancy Services Ltd`,
					description: post.caseStudyFields.description,
					type: "article",
					images: [
						{
							url: `${post.caseStudyFields.image.localFile.publicURL}`,
							width: `${post.caseStudyFields.image.localFile.childImageSharp.original.width}`,
							height: `${post.caseStudyFields.image.localFile.childImageSharp.original.height}`,
							alt: `${post.caseStudyFields.image.altText}`,
						},
					],
				}}
			/>
			<section>
				<BgImage
					Tag="section"
					className="hero-services position-relative  py-5 py-lg-0"
					image={getImage(
						post.caseStudyFields.image.localFile.childImageSharp.gatsbyImageData
					)}
				>
					<Container fluid className="h-100 position-relative  px-lg-0 mx-lg-0">
						<Row className=" px-lg-0 mx-lg-0 h-100  align-items-end">
							<Col lg={8} xl={7} className="position-relative  p-lg-5">
								<div
									style={{ opacity: 0.8, zIndex: 0 }}
									className="w-100 h-100 position-absolute start-0 top-0 bg-black"
								></div>
								<h1
									style={{ zIndex: 2 }}
									className="pt-3 mb-3 text-white position-relative display-4"
									id="services-hero-title"
								>
									{post.title}
								</h1>
							</Col>
						</Row>
					</Container>
				</BgImage>
			</section>
			<section>
				<Container className=" py-md-7 py-5">
					<Row className="justify-content-center">
						<Col>
							<div className="article-content px-md-4">
								{parse(post.content)}
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};

export default CaseTemplate;

export const pageQuery = graphql`
	query CaseTemplateById($id: String!) {
		post: wpCaseStudy(id: { eq: $id }) {
			id

			slug
			content
			title

			caseStudyFields {
				image {
					altText
					localFile {
						childImageSharp {
							original {
								height
								width
							}
							gatsbyImageData(
								formats: [AUTO, WEBP]
								quality: 70
								transformOptions: { cropFocus: CENTER, fit: CONTAIN }
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
				description
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
