import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import ReviewSection from "../components/review-section";
import CTASection from "../components/cta-section";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const ClientsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpClient(sort: { fields: dateGmt, order: DESC }) {
				nodes {
					clientFields {
						backgroundColour
						description
						textColour
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: FULL_WIDTH
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			heroImg: wpMediaItem(title: { eq: "Clients-Hero" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const responsive = {
		0: {
			items: 1,
		},
	};
	const responsive2 = {
		0: {
			items: 2,
		},
	};
	const responsive3 = {
		0: {
			items: 3,
		},
	};

	const items = data.allWpClient.nodes.map((client, i) => (
		<div className=" px-0 mx-0">
			<Row className="px-0 mx-0">
				<Col
					xs={{
						span: 12,
						order: `${i % 2 === 0 ? "first" : "last"}`,
					}}
					className="px-0 mx-0"
				>
					<div
						style={{ paddingTop: "100%" }}
						className="position-relative bg-white w-100"
					>
						<div className="position-absolute w-80 start-50 top-50 translate-middle">
							<GatsbyImage
								className="w-100 "
								image={
									client.clientFields.image.localFile.childImageSharp
										.gatsbyImageData
								}
								alt={client.clientFields.image.altText}
							/>
						</div>
					</div>
				</Col>
				<Col xs={12} className="px-0 mx-0">
					<div
						style={{
							paddingTop: "100%",
							backgroundColor: client.clientFields.backgroundColour,
						}}
						className="position-relative  w-100"
					>
						<div className="position-absolute w-100 p-md-5 p-4 start-50 top-50 translate-middle">
							<p
								className="small-p"
								style={{
									color: client.clientFields.textColour,
								}}
							>
								{client.clientFields.description}
							</p>
						</div>
					</div>
				</Col>
			</Row>
		</div>
	));

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Clients",
				item: {
					url: `${siteUrl}/clients`,
					id: `${siteUrl}/clients`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Clients | ACM Consultancy Services Ltd"
				description="Clients are organisations or projects of all sizes that wish to drive towards sustainability. Typical experience in the rail and infrastructure and the water and environment sectors (I.e. regenerative agriculture, ecology, sustainability delivery through flood defence, or waste technology projects)."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/clients`,
					title: "Clients | ACM Consultancy Services Ltd",
					description:
						"Clients are organisations or projects of all sizes that wish to drive towards sustainability. Typical experience in the rail and infrastructure and the water and environment sectors (I.e. regenerative agriculture, ecology, sustainability delivery through flood defence, or waste technology projects).",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section>
						<Hero
							bgPos="bottom"
							backgroundImage={
								data.heroImg.localFile.childImageSharp.gatsbyImageData
							}
							title="Clients"
							description="Clients are organisations or projects of all sizes as we are scaleable and adaptable. Typical clients we have delivered multiple benefits for include: Rail and Infrastructure and the Water and Environment sectors. We also work with associates and partners to deliver regenerative agriculture, ecology, carbon, circular economy and other specialist inputs. We partner with a breadth of innovative technology."
						/>
					</section>
					<section className="py-5 py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary text-center display-4">
										Current & Past
									</h2>
								</Col>
							</Row>
						</Container>
						<div className="px-2 d-md-none">
							<AliceCarousel
								infinite={true}
								responsive={responsive}
								mouseTracking
								items={items}
								disableDotsControls
								autoPlay
								autoPlayInterval={2000}
							/>
						</div>
						<div className="px-2 d-none d-md-flex d-xl-none">
							<AliceCarousel
								infinite={true}
								responsive={responsive2}
								mouseTracking
								items={items}
								disableDotsControls
								autoPlay
								autoPlayInterval={2000}
							/>
						</div>
						<div className="px-2 d-none d-xl-flex">
							<AliceCarousel
								infinite={true}
								responsive={responsive3}
								mouseTracking
								items={items}
								disableDotsControls
								autoPlay
								autoPlayInterval={2000}
							/>
						</div>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container fluid>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default ClientsPage;
