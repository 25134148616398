import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function ContactForm() {
	const [state, handleSubmit] = useForm("xdojznqy");
	if (state.succeeded) {
		return (
			<div>
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row className="gx-5">
						<Col xs={6}>
							<Form.Group className="mb-4" controlId="name">
								<Form.Label className="garamond-bold">Name *</Form.Label>
								<Form.Control className="py-3" name="name" type="text" />
							</Form.Group>
							<ValidationError
								prefix="name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col xs={6}>
							<Form.Group className="mb-4" controlId="phone">
								<Form.Label className="garamond-bold">
									Mobile Number *
								</Form.Label>
								<Form.Control className="py-3" name="phone" type="tel" />
							</Form.Group>
							<ValidationError
								prefix="Phone"
								field="phone"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Form.Group className="mb-4" controlId="email">
						<Form.Label className="garamond-bold">Email *</Form.Label>
						<Form.Control className="py-3" name="_replyto" type="email" />
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-4" controlId="message">
						<Form.Label className="garamond-bold">Message *</Form.Label>
						<Form.Control
							className="py-3"
							name="message"
							as="textarea"
							rows={5}
						/>
					</Form.Group>
					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>
					{/* <div
				class="g-recaptcha"
				data-sitekey="6LcauP0gAAAAAMl9A0wI1H7n2ZyRHqx2MpLoxl5Y"
			></div> */}
					<div className="">
						<Button
							variant="primary"
							style={{ borderRadius: "0px" }}
							className="btn western text-white w-100 w-md-auto  white-link-yellow px-5 mt-4 mt-md-0  py-2 "
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							SEND
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row className="gx-5">
				<Col xs={6}>
					<Form.Group className="mb-4" controlId="name">
						<Form.Label className="garamond-bold">Name *</Form.Label>
						<Form.Control className="py-3" name="name" type="text" />
					</Form.Group>
					<ValidationError prefix="name" field="name" errors={state.errors} />
				</Col>
				<Col xs={6}>
					<Form.Group className="mb-4" controlId="phone">
						<Form.Label className="garamond-bold">Mobile Number *</Form.Label>
						<Form.Control className="py-3" name="phone" type="tel" />
					</Form.Group>
					<ValidationError prefix="Phone" field="phone" errors={state.errors} />
				</Col>
			</Row>

			<Form.Group className="mb-4" controlId="email">
				<Form.Label className="garamond-bold">Email *</Form.Label>
				<Form.Control className="py-3" name="_replyto" type="email" />
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-4" controlId="message">
				<Form.Label className="garamond-bold">Message *</Form.Label>
				<Form.Control className="py-3" name="message" as="textarea" rows={5} />
			</Form.Group>
			<ValidationError prefix="Message" field="message" errors={state.errors} />
			{/* <div
				class="g-recaptcha"
				data-sitekey="6LcauP0gAAAAAMl9A0wI1H7n2ZyRHqx2MpLoxl5Y"
			></div> */}
			<div className="">
				<Button
					variant="primary"
					style={{ borderRadius: "0px" }}
					className="btn western text-white w-100 w-md-auto  white-link-yellow px-5 mt-4 mt-md-0  py-2 "
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					SEND
				</Button>
			</div>
		</Form>
	);
}
export default ContactForm;
