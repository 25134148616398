import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import AnimatedButton from "./animated-button";

const Hero = ({ title, description, backgroundImage, bgPos }) => {
	const pluginImage = getImage(backgroundImage);

	return (
		<BgImage
			Tag="section"
			className="hero-services position-relative py-5 py-lg-0"
			image={pluginImage}
			style={{ backgroundPosition: bgPos }}
		>
			<div
				style={{ opacity: 0.5, zIndex: 0 }}
				className="w-100 h-100 position-absolute start-0 top-0 bg-black"
			></div>
			<Container style={{ zIndex: 2 }} className="h-100 position-relative">
				<Row className=" pt-3 px-3 h-100 align-items-center">
					<Col lg={9} xl={7} className="pl-0 ps-md-3">
						<h1
							className="pt-3 mb-3 text-white display-4"
							id="services-hero-title"
						>
							{title}
						</h1>
						<p className="text-white mb-4">{description}</p>
						<AnimatedButton
							textColour="text-white"
							buttonColour="bg-primary"
							textWidth="180px"
							buttonId="hero"
							buttonText="GET IN TOUCH"
							buttonLink="/contact-us"
						/>
					</Col>
				</Row>
			</Container>
		</BgImage>
	);
};

export default Hero;
