import React from "react";
import { Button } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";
import gsap from "gsap";
import { Link } from "gatsby";
const CSRCardButton = ({
	textColour,
	buttonColour,
	buttonText,
	buttonId,
	textWidth,
	buttonLink,
}) => {
	const enterAnimation = (btn) => {
		gsap.to(
			btn,

			{ maxWidth: "300px", duration: 0.5 }
		);
	};
	const exitAnimation = (btn) => {
		gsap.to(btn, { maxWidth: "42px", duration: 0.5 });
	};

	return (
		<Button
			// as={Link}
			// to={buttonLink}
			onMouseEnter={() => enterAnimation(`.${buttonId}`)}
			onMouseLeave={() => exitAnimation(`.${buttonId}`)}
			style={{
				borderRadius: "200px",
				overflow: "hidden",
				border: "0px",
				backgroundColor: buttonColour,
			}}
			className={` ${textColour} p-0 ${buttonId} button-hidden d-flex`}
		>
			<Link
				to={buttonLink}
				style={{ fontSize: "100%" }}
				className={`d-flex p-0  align-items-center justify-content-center text-decoration-none ${textColour}`}
			>
				<div
					className="d-flex  align-items-center me-2 justify-content-center"
					style={{ width: "42px", height: "42px" }}
				>
					<FiArrowRight className="fs-4" />
				</div>
				<div
					style={{ minWidth: textWidth }}
					className={`fs-5 mb-0 pb-0 mt-1 me-3 garamond-regular`}
				>
					{buttonText}
				</div>
			</Link>
		</Button>
	);
};

export default CSRCardButton;
