import React from "react";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Row } from "react-bootstrap";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const ReviewSection = () => {
	const data = useStaticQuery(graphql`
		query {
			img: wpMediaItem(title: { eq: "ACM-greycasle" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			allWpTestimonial {
				nodes {
					testimonialFields {
						review
					}
				}
			}
		}
	`);

	const responsive = {
		0: {
			items: 1,
		},

		1200: {
			items: 3,
		},
	};
	const responsive2 = {
		0: {
			items: 2,
		},

		1200: {
			items: 3,
		},
	};
	const responsive3 = {
		0: {
			items: 3,
		},

		1200: {
			items: 3,
		},
	};

	const items = data.allWpTestimonial.nodes.map((review) => (
		<Col className="py-4 px-4   ">
			<div
				style={{ borderRadius: "10px" }}
				className="position-relative bg-white p-4 "
			>
				<GatsbyImage
					loading="eager"
					style={{ opacity: 0.35 }}
					className="w-100"
					image={data.img.localFile.childImageSharp.gatsbyImageData}
					alt={data.img.altText}
				/>
				<div className="position-absolute p-md-5 p-4 w-100 start-50 top-50 translate-middle">
					<p style={{ fontSize: "100%" }}>{review.testimonialFields.review}</p>
				</div>
			</div>
		</Col>
	));

	return (
		<>
			<Row className="justify-content-center d-lg-none ">
				<AliceCarousel
					infinite={true}
					responsive={responsive}
					mouseTracking
					items={items}
					disableDotsControls
					autoPlay
					autoPlayInterval={2000}
				/>
			</Row>
			<Row className="justify-content-center d-none d-lg-flex d-xxl-none ">
				<AliceCarousel
					infinite={true}
					responsive={responsive2}
					mouseTracking
					items={items}
					disableDotsControls
					autoPlay
					autoPlayInterval={2000}
				/>
			</Row>
			<Row className="justify-content-center d-none d-xxl-flex ">
				<AliceCarousel
					infinite={true}
					responsive={responsive3}
					mouseTracking
					items={items}
					disableDotsControls
					autoPlay
					autoPlayInterval={2000}
				/>
			</Row>
		</>
	);
};

export default ReviewSection;
