import { Link, useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import AnimatedButton from "../components/animated-button";
import HoverButton from "../components/hover-button";
import Marquee from "react-fast-marquee";
import ReviewSection from "../components/review-section";
import CTASection from "../components/cta-section";

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpTeamMember {
				nodes {
					title
					slug
					teamMemberFields {
						jobTitle
						name
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
					}
				}
			}
			allWpCaseStudy {
				nodes {
					title
					caseStudyFields {
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 70
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
								}
							}
						}
						description
					}
					slug
				}
			}
			heroImg: wpMediaItem(title: { eq: "Home-Hero-1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg2: wpMediaItem(title: { eq: "Home-Hero-2" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg3: wpMediaItem(title: { eq: "Home-Hero-3" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			heroImg4: wpMediaItem(title: { eq: "Home-Hero-4" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ctaImg: wpMediaItem(title: { eq: "Home-Get-In-Touch" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			esacImg: wpMediaItem(title: { eq: "Home-ESAC" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 50
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ecsmImg: wpMediaItem(title: { eq: "train" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			esgImg: wpMediaItem(title: { eq: "ESG" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			eiaImg: wpMediaItem(title: { eq: "EIA-Management" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			ssprImg: wpMediaItem(title: { eq: "Sustainability-Strategy" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			trainImg: wpMediaItem(title: { eq: "train" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img1: wpMediaItem(title: { eq: "green-world-awards" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			img2: wpMediaItem(title: { eq: "logo1" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			img3: wpMediaItem(title: { eq: "business-excellence" }) {
				altText
				localFile {
					publicURL
					childImageSharp {
						original {
							height
							width
						}
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 70
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: TRACED_SVG
						)
					}
				}
			}
			allWpPartnership {
				nodes {
					partnershipFields {
						link
						image {
							altText
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 40
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: TRACED_SVG
									)
								}
							}
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title="Home | ACM Consultancy Services Ltd"
				description="ACM Consultancy Services Ltd. is a bespoke Environment and Sustainability Consultancy firm, which drives an equilibrium of environmental and operational performance, within the public and private sector, to empower a globally sustainable future through innovation, collaboration and expertise."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | ACM Consultancy Services Ltd",
					description:
						"ACM Consultancy Services Ltd. is a bespoke Environment and Sustainability Consultancy firm, which drives an equilibrium of environmental and operational performance, within the public and private sector, to empower a globally sustainable future through innovation, collaboration and expertise.​",
					images: [
						{
							url: `${data.heroImg?.localFile.publicURL}`,
							width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
							height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroImg?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout>
					<section className="">
						<Container fluid className="px-lg-0 ps-lg-7 pe-lg-0  mx-lg-0">
							<Row>
								<Col className="px-0 mx-0" lg={7}>
									<Row
										style={{ height: "30vh", overflow: "hidden" }}
										className="justify-content-end d-none d-lg-flex"
									>
										<Col lg={6}>
											{" "}
											<GatsbyImage
												className="w-100"
												image={
													data.heroImg.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.heroImg.altText}
											/>
										</Col>
									</Row>
									<Row className="justify-content-end mb-4 d-lg-none">
										<Col lg={6}>
											{" "}
											<GatsbyImage
												className="w-100"
												image={
													data.heroImg.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.heroImg.altText}
											/>
										</Col>
									</Row>
									<Row className="align-items-center home-hero-text ps-lg-0 px-4 pe-lg-5">
										<Col className="py-4">
											<h1 className="text-primary display-4">
												Problem Solving Made Easy
											</h1>
											<p>
												ACM Consultancy Services Ltd. is a bespoke Environment
												and Sustainability Consultancy firm, which drives an
												equilibrium of environmental and operational
												performance, within the public and private sector, to
												empower a globally sustainable future through
												innovation, collaboration and expertise.​
											</p>
											<AnimatedButton
												textColour="text-white"
												buttonColour="bg-primary"
												textWidth="180px"
												buttonId="one"
												buttonText="GET IN TOUCH"
												buttonLink="/contact-us"
											/>
										</Col>
									</Row>
									<Row className=" py-3 d-lg-none align-items-center justify-content-center px-4">
										<Col className="d-flex align-items-center justify-content-center">
											{" "}
											<GatsbyImage
												style={{ maxWidth: "70px" }}
												className="w-100 d-inline-block me-4"
												image={
													data.img1.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.img1.altText}
											/>{" "}
											<GatsbyImage
												style={{ maxWidth: "140px" }}
												className="w-100 d-inline-block"
												image={
													data.img3.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.img3.altText}
											/>
										</Col>
									</Row>
									<Row
										style={{ height: "30vh", overflow: "hidden" }}
										className="justify-content-end align-items-end d-none d-lg-flex"
									>
										<Col className="text-end" lg={2}>
											{" "}
											<GatsbyImage
												style={{ maxWidth: "70px" }}
												className="w-100"
												image={
													data.img1.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.img1.altText}
											/>
										</Col>
										<Col lg={3}>
											{" "}
											<GatsbyImage
												className="w-100"
												image={
													data.img3.localFile.childImageSharp.gatsbyImageData
												}
												alt={data.img3.altText}
											/>
										</Col>
										<Col lg={6}>
											{" "}
											<GatsbyImage
												className="w-100"
												image={
													data.heroImg3.localFile.childImageSharp
														.gatsbyImageData
												}
												alt={data.heroImg3.altText}
											/>
										</Col>
									</Row>
								</Col>
								<Col className="px-0 mx-0 d-none d-lg-block" lg={5}>
									<GatsbyImage
										style={{ height: "50vh" }}
										className="w-100"
										image={
											data.heroImg2.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.heroImg2.altText}
									/>
									<GatsbyImage
										style={{ height: "50vh" }}
										className="w-100"
										image={
											data.heroImg4.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.heroImg4.altText}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<p className="garamond-bold text-lg-center">
										As a passionate SME, we create rational change, to
										transition and drive towards meaningful holistic outcomes
										that work now and in the future. We achieve maximum value
										for our client ecosystems by delivering the six key steps
										within our{" "}
										<Link
											className="primary-link garamond-bold"
											to="/netbusinesspositive"
										>
											Mission Statement
										</Link>
										.
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container fluid className="px-lg-2 mx-lg-0">
							<Row className="px-lg-0 g-4 mx-lg-0">
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="  d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/championing-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "80px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/championing-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "60px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block ">
												Championing
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">
												Championing
											</h2>
										</div>
									</div>
								</Col>
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/empowerment-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "60px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/empowerment-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "40px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block">
												Empowerment
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">
												Empowerment
											</h2>
										</div>
									</div>
								</Col>
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Experience-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "80px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Experience-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "60px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block">
												Experience
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">
												Experience
											</h2>
										</div>
									</div>
								</Col>
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Facilitating-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "80px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Facilitating-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "60px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block">
												Facilitating
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">
												Facilitating
											</h2>
										</div>
									</div>
								</Col>
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Resource-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "70px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Resource-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "50px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block">
												Resource
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">
												Resource
											</h2>
										</div>
									</div>
								</Col>
								<Col xs={6} xl={4}>
									<div className="hover-tile">
										<div className="position-absolute start-50 top-50 text-center translate-middle">
											<div
												className="d-none d-md-flex align-items-center justify-content-center"
												style={{ height: "120px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Legacy-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "60px" }}
												/>
											</div>
											<div
												className="d-flex d-md-none align-items-center justify-content-center"
												style={{ height: "90px" }}
											>
												<StaticImage
													quality="70"
													src="../images/Legacy-green.svg"
													alt="Championing"
													placeholder="traced_svg"
													formats={["auto", "webp"]}
													style={{ maxWidth: "40px" }}
												/>
											</div>
											<h2 className="garamond-bold mt-2 d-none d-md-block">
												Legacy
											</h2>
											<h2 className="garamond-bold  fs-5 d-md-none ">Legacy</h2>
										</div>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container fluid className="px-lg-0 mx-lg-0 bg-dark-grey">
							<Row className="px-lg-0 mx-lg-0 align-items-center">
								<Col className="px-0 mx-0" lg={{ span: 5 }}>
									<GatsbyImage
										className="w-100 "
										image={
											data.ctaImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.ctaImg.altText}
									/>
								</Col>
								<Col className="px-lg-7 px-4 py-5 py-lg-0" lg={7}>
									<h2 className="text-white fs-1">Get in touch</h2>
									<p className="text-white">
										Why not get in touch so we can demonstrate exactly how we
										will deliver our mission statement and create thriving,
										quality operations and outcomes for your business?
									</p>
									<HoverButton
										textColour="text-white"
										buttonColour="bg-dark-grey"
										textWidth="145px"
										buttonId="two"
										buttonText="Talk to us"
										buttonLink="/contact-us"
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 py-lg-7">
						<h2 className="text-center mb-5 text-primary display-4">
							Our Valued Partnerships
						</h2>
						<div className="pb-md-5 ">
							<Marquee speed="40" gradient={false}>
								{data.allWpPartnership.nodes
									.filter(
										(item, index) =>
											index < data.allWpPartnership.nodes.length / 2
									)
									.map((partner) => (
										<div className="px-4">
											<a
												target="_blank"
												rel="noreferrer"
												href={partner.partnershipFields.link}
											>
												<GatsbyImage
													loading="eager"
													style={{ maxWidth: "200px" }}
													image={
														partner.partnershipFields.image.localFile
															.childImageSharp.gatsbyImageData
													}
													alt={partner.partnershipFields.image.altText}
												/>
											</a>
										</div>
									))}
							</Marquee>
						</div>
						<Marquee direction="right" speed="40" gradient={false}>
							{data.allWpPartnership.nodes
								.filter(
									(item, index) =>
										index >= data.allWpPartnership.nodes.length / 2
								)
								.map((partner) => (
									<div className="px-4">
										<a
											target="_blank"
											rel="noreferrer"
											href={partner.partnershipFields.link}
										>
											<GatsbyImage
												loading="eager"
												style={{ maxWidth: "200px" }}
												image={
													partner.partnershipFields.image.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={partner.partnershipFields.image.altText}
											/>
										</a>
									</div>
								))}
						</Marquee>
					</section>
					<section>
						<Container>
							<Row>
								<Col className="text-center">
									<h2 className="garamond-bold">
										Our pursuit of the 17 UN Sustainable Development Goals
										(UNSDGs)
									</h2>
									<p className="text-dark-green garamond-bold mb-5">
										Heartfelt drive towards true sustainability
									</p>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center bg-light-grey">
								<Col lg={6} className="px-0 mx-0">
									<GatsbyImage
										className="w-100 home-services"
										image={
											data.esacImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.esacImg.altText}
									/>
								</Col>
								<Col lg={6} className="px-0 mx-0 ">
									<div className=" py-5 px-4 px-md-5">
										<h3 className="text-primary">
											Environment and Sustainability Advisors and Consultants
										</h3>
										<p>
											Our optimal performance is achieved through informed
											insight and effective critical thinking. We drive our
											expert skills, technical capabilities and creative problem
											solving approaches into the heart of a project or
											organisation to deliver optimal environmental and
											operational performance that works now and in the future.
										</p>
										<p>
											We champion fresh thinking and upskilling to achieve
											optimised results and innovation. We deliver a holistic
											mindset approach to sustainability as a team, which means
											we consider the wider picture, as well as the short term
											gains.
										</p>
										<AnimatedButton
											textColour="text-white"
											buttonColour="bg-primary"
											textWidth="145px"
											buttonId="three"
											buttonText="READ MORE"
											buttonLink="/services"
										/>
									</div>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center bg-dark-green">
								<Col lg={{ span: 6, order: "last" }} className="px-0 mx-0">
									<GatsbyImage
										className="w-100 home-services"
										image={
											data.ecsmImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.ecsmImg.altText}
									/>
								</Col>
								<Col lg={6} className="px-0 mx-0 ">
									<div className=" py-5 px-4 px-md-5">
										<h3 className="text-secondary">
											ISO14001 Environment, Consenting and Sustainability
											Management
										</h3>
										<p className="text-white">
											We drive collaboration across the ISO framework and
											externally, in order to foster trust between all
											stakeholders, which is integral to facilitating a forum
											for innovation and the perspective on what is possible.
										</p>
										<p className="text-white">
											We research new and emerging issues so that ISO14001
											frameworks can transition to be future-facing and
											holistic, in order to be prepared for, and champion,
											national and international commitments.{" "}
										</p>
										<p className="text-white">
											We deliver inclusive and agile-working, and champion
											innovation and up-skilling through skilled members of our
											team. We collaborate with teams to share the import of
											sustainability solutions to a range of complex issues.
										</p>

										<AnimatedButton
											textColour="text-white"
											buttonColour="bg-secondary"
											textWidth="145px"
											buttonId="four"
											buttonText="READ MORE"
											buttonLink="/services"
										/>
									</div>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center bg-primary">
								<Col lg={6} className="px-0 mx-0">
									<GatsbyImage
										className="w-100 home-services"
										image={
											data.esgImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.esgImg.altText}
									/>
								</Col>
								<Col lg={6} className="px-0 mx-0 ">
									<div className=" py-5 px-4 px-md-5">
										<h3 className="text-white">
											Sustainability Strategy, Implementation and Performance
											Reporting:
										</h3>
										<p className="text-white">
											Our talented sustainability professionals, who are
											continually on the cutting edge of continued professional
											development, will create precise and visually engaging
											strategic sustainability reports or road maps bespoke to
											new legislation, industry expectations, and organisational
											or programme needs.{" "}
										</p>
										<p className="text-white">
											We will transition this optimal sustainability programme
											into implementation (as required), imparting knowledge
											that will directly or indirectly influence wider attitudes
											and beliefs and transition your internal and external
											stakeholders towards behavioural change.{" "}
										</p>
										<p className="text-white">
											We will provide quality technical consultation, impact
											evaluation, strategise transformative solutions and manage
											progress through delivery. Sustainability and Sustainable
											Development are at the heart of ACM.
										</p>

										<AnimatedButton
											textColour="text-black"
											buttonColour="bg-white"
											textWidth="145px"
											buttonId="five"
											buttonText="READ MORE"
											buttonLink="/services"
										/>
									</div>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center bg-white">
								<Col lg={{ span: 6, order: "last" }} className="px-0 mx-0">
									<GatsbyImage
										className="w-100 home-services"
										image={
											data.eiaImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.eiaImg.altText}
									/>
								</Col>
								<Col lg={6} className="px-0 mx-0 ">
									<div className=" py-5 px-4 px-md-5">
										<h3 className="text-primary">EIA Management</h3>
										<p className="">
											We will deliver your EIA development (from screening and
											scoping, through to ES production, review, assurance and
											sign-off). We will integrate environment, sustainability,
											social and economic impact 'avoidance', 'mitigation',
											'offsetting/compensation' and 'benefits' (in that order)
											into your development.{" "}
										</p>
										<p className="">
											Through our high-quality technical expertise,
											high-performing quality assurance and dynamic and scalable
											resource model, we will define your project resources and
											translate objectives into the desired outcomes in terms of
											time, accuracy and quality.
										</p>

										<AnimatedButton
											textColour="text-white"
											buttonColour="bg-primary"
											textWidth="145px"
											buttonId="six"
											buttonText="READ MORE"
											buttonLink="/services"
										/>
									</div>
								</Col>
							</Row>
						</Container>
						<Container fluid className="px-lg-0 mx-lg-0">
							<Row className="px-lg-0 mx-lg-0 align-items-center bg-dark-grey">
								<Col lg={6} className="px-0 mx-0">
									<GatsbyImage
										className="w-100 home-services"
										image={
											data.ssprImg.localFile.childImageSharp.gatsbyImageData
										}
										alt={data.ssprImg.altText}
									/>
								</Col>
								<Col lg={6} className="px-0 mx-0 ">
									<div className=" py-5 px-4 px-md-5">
										<h3 className="text-secondary">
											Environmental, Social, Governance (ESG)
										</h3>
										<p className="text-white">
											Broaden Your Positive ESG Impact. Our involvement will
											leave a tangible positive, green legacy for your business.
											We will work with our partners to transition your
											forward-thinking organisation towards sustainable business
											operations that sit in line with environment/ social/
											governance compliance and importantly, emerging
											sustainability and investment developments.
										</p>
										<p className="text-white">
											We will provide you with a gap analysis and benchmarking,
											discuss the results and then deliver you tailored,
											multi-facetted solutions. We will help you develop a
											cultural sustainability ethos to benefit yout business,
											stakeholders and your local community, now and in the
											future.
										</p>

										<AnimatedButton
											textColour="text-white"
											buttonColour="bg-secondary"
											textWidth="145px"
											buttonId="seven"
											buttonText="READ MORE"
											buttonLink="/services"
										/>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="py-5 bg-light-grey py-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className=" ps-4 mb-5 text-primary display-4">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<ReviewSection />
						</Container>
					</section>
					<section>
						<CTASection />
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
